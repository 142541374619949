import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigsService {

  constructor() { }

  getKeyCloakAddress() {
    return environment.KEYCLOAK_SERVER_ADDR;
  }
  
  getKeyCloakRealm() {
    return environment.KEYCLOAK_REALM;
  }
  
  getKeyCloakClientID() {
    return environment.KEYCLOAK_CLIENT_ID;
  }
  
  getServerURL() {
    return environment.SERVER_BACK_ADDR;
  }
  
  getServerFrontURL() {
    return environment.SERVER_FRONT_ADDR; 
  }

  getServerFrontURLEncoded() {
    return environment.SERVER_FRONT_ADDR_ENCODED; 
  }
  
  // getServerLoginURLEncoded() {
  //   return environment.SERVER_FRONT_LOGIN_ADDR_ENCODED; 
  // }
}
