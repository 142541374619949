

import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
 
@Injectable()
export class WsService {
 
    constructor(private socket: Socket) { }
 
    sendMessage(msg: string){
        this.socket.emit("message", msg);
    }
    //  getMessage() {
    //     return this.socket
    //         .fromEvent("message")
    //         .map( data => data.msg );
    // }
}
 

// import { webSocket } from 'rxjs/webSocket' // for RxJS 6, for v5 use Observable.webSocket

//  let subject = webSocket('ws://localhost:8081');
//  subject.subscribe(
//     (msg) => console.log('message received: ' + msg),
//     (err) => console.log(err),
//     () => console.log('complete')
//   );
//  subject.next(JSON.stringify({ op: 'hello' }));