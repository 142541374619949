import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {DocumentoService} from '../../services/documento.service';
import {NormaService} from '../../services/norma.service';
import {AuthService} from '../../services/auth.service';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DocViewerComponent} from '../doc-viewer/doc-viewer.component';
import {NormaDetailComponent} from '../norma-detail/norma-detail.component';
import {CommunicationService} from '../../services/communication.service';
import { Subscription } from 'rxjs';

import {TableModule} from 'primeng/table';

interface tipoNormaLegal {
  id: string;
  descripcion: string;
}

export interface LazyLoadEvent {
  first?: number;
  rows?: number;
  sortField?: string;
  sortOrder?: number;
  filters?: {[s: string]: FilterMetadata;};
}

export interface FilterMetadata {
  value?: string;
  matchMode?: string;
}

@Component({
  selector: 'app-tabla-normas',
  templateUrl: './tabla-normas.component.html',
  styleUrls: ['./tabla-normas.component.scss']
})
export class TablaNormasComponent implements OnInit, OnDestroy {

  @ViewChild('tbl_data') 
  tbl_data: TableModule;

  userDataArea = '';
  userRol = '';

  tbl_rows_data: any[];
  tbl_columns: any[];
  tbl_first = 0;
  tbl_rows = 15;
  tbl_totalRecords = 0;
  
  f_display_pdf = false;
  f_display_pdf_erase_confirmation = false;
  display_uploadDocDialog = false;
  voces: any[];
  dialogRef: any;
  dialogDetailRef: any;
  
  caracter: any[];
  inFechaDesde = '';
  inFechaHasta = '';
  inNumero = '';
  inResena = '';
  inCaracter = '';
  inVoz = '';
  tipoNormaLegalSelected: tipoNormaLegal = undefined;
  filterErrorMessage = '';
  showFilterWarning = false;
  tiposNormasPorOficina: any;
  subscription: Subscription;

  showProgressSpinnerTable = false;
  showSearchComponents = true;

  panel_collapsed = true;

  tiposNormasEditables = [];

  id_pdf = '';

  constructor(
    private documentoService: DocumentoService,
    private normaService: NormaService,
    private authService: AuthService,
    public dialog: MatDialog,
    private communicationService: CommunicationService,
    public dialogDetail: MatDialog,
    private router: Router,
    private route: ActivatedRoute
    ) {

    this.subscription = this.communicationService.getMessage().subscribe(message => {
      console.log('GOT THE ERASE EVENT FROM OTHER _>>>>>>>');
      console.log(message);
      if (message['text']['status'] == 'success') {
        console.log('norma appears to be deleted, procceeding to update main table here..');
        this.cleanFilters();

      }
    });
   }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {

      this.id_pdf = params['nl'] || '';

      if (this.id_pdf) {

        this.openDialog(this.id_pdf);
      }
    });

    this.tbl_columns = [
      { field: 'descripcion_tipo_nl', header: 'Tipo', stl: '19%'},
      { field: 'numero', header: 'Número', stl: '8%'},
      { field: 'fecha_firma', header: 'Fecha', stl: '8%'},
      { field: 'descripcion_voz_nl', header: 'Voz', stl: '15%'},
      { field: 'resena_short', header: 'Tema', stl: '32%'},
      { field: 'ABM', header: '', stl: '8%'},
    ];

    this.normaService.setNormaLegalToUpdate('', '', '');

    // obtengo el rol del usuario login o PUBLIC si es un usuario publico
    this.userRol = this.authService.getUserRol();

    this.normaService.getTiposNormas().subscribe(data => {

      const obj = [];
      obj.push({'id': 'TODAS', 'descripcion': 'TODAS'});

      if (data['data'] !== undefined) {

        for (let a = 0; a < data['data'].length; a++) {

          obj.push({'id': data['data'][a]['id'], 'descripcion': data['data'][a]['descripcion']});
        }
        this.tiposNormasPorOficina = [...obj];
      }
    });

    if (this.userRol !== 'PUBLIC') {
      // si es un usuario login
      // obtengo todos los tipos de normas legales validos para el usuario login

      this.userDataArea = this.authService.getUserDataArea()[0];

      this.normaService.getOficinas(null, this.userDataArea).subscribe(data => {

        if (data['data'] !== undefined) {

          const _id_oficina = data['data'][0]['id'];

          this.normaService.getTiposNormasPorOficina(_id_oficina).subscribe(data => {

            if (data['data'] !== undefined) {

              for (let a = 0; a < data['data'].length; a++) {
                
                this.tiposNormasEditables.push(data['data'][a].id_tipo_nl);
              }
            }
          });
        }
      });
    } 

    this.normaService.getVoces().subscribe(data => {

      const obj = [];
      obj.push({'label': 'TODAS', 'value': ''});

      if (data['data'] !== undefined) {

        for (let a = 0; a < data['data'].length; a++) {

          obj.push({'label': data['data'][a]['descripcion'], 'value': data['data'][a]['id']});
        }
      }

      this.voces = [...obj];
    });

    this.caracter = [
      {'label': 'TODAS', 'value': ''},
      {'label': 'INDIVIDUAL', 'value': 'individual'},
      {'label': 'GENERAL', 'value': 'general'}
    ];
  }

  resetDT(dt: TableModule) {

    const event = {
      'first': this.tbl_first,
      'rows': this.tbl_rows
    }

    dt['first'] = 0;
    this.buscarNormasLegales(event);
  }

  openDialog(par_id_pdf): void {

    this.dialogRef = this.dialog.open(DocViewerComponent, {
      width: '90%',
      height: '70%',
      data: {id_pdf: par_id_pdf }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setColumnWidth(col) {

    for (let a = 0; a < this.tbl_columns.length; a++) {
      if (col['field'] == this.tbl_columns[a].field) {
        return this.tbl_columns[a].stl;
      }
    }
  }

  openDetailDialog(item): void {

    this.showProgressSpinnerTable = true;

    this.documentoService.getNormaLegal(item.id, this.userRol).subscribe(data => {

      this.showProgressSpinnerTable = false;

      this.dialogDetailRef = this.dialogDetail.open(NormaDetailComponent, {
        width: '80%',
        height: '80%',
        data: {data: data['data'][0] , userRol: this.userRol, editable: item.editable}
      });

      // resp['data'][i]['editable'] = nl_editable;

      this.dialogDetailRef.afterClosed().subscribe(result => {
        console.log('The Detail dialog was closed');
      });
    });
  }

  display_pdf_erase_confirmation() {
    this.f_display_pdf_erase_confirmation = true;
  }

  uploadDoc() {
    this.display_uploadDocDialog = true;
  }

  DocUploader(doc) {
    const meta = {};
    meta['numero'] = 999;
    this.documentoService.postDoc(doc.files[0], 'something').subscribe(data => console.log('got some response from backend. probably doc has been inserted'));
  }

  buscarNormasLegales(event: LazyLoadEvent) {

    this.tbl_rows = event.rows ? event.rows : this.tbl_rows;

    let passed = true;
    let errMsg = '';

    this.tbl_rows_data = [];

    this.showSearchComponents = false;


    this.showProgressSpinnerTable = true;

    const criterios_busqueda = {}

    criterios_busqueda['idTipo'] = this.tipoNormaLegalSelected !== undefined && this.tipoNormaLegalSelected['id'] !== 'TODAS' ? this.tipoNormaLegalSelected['id'] : '';
    criterios_busqueda['caracter'] = this.inCaracter;
    criterios_busqueda['numero'] = this.inNumero;
    criterios_busqueda['resena'] = this.inResena;
    criterios_busqueda['idVoz'] = this.inVoz;

    if (this.inFechaDesde === 'yyyy/mm/dd') {
      this.inFechaDesde = '';
    }
    if (this.inFechaDesde !== '') {
      const dateTempYYYY = this.inFechaDesde.split('/')[2];
      const dateTempMM = this.inFechaDesde.split('/')[1];
      const dateTempDD = this.inFechaDesde.split('/')[0];
      const n = new Date();
      const today = this.addZero(n.getDate().toString()) + '/' + this.addZero((n.getMonth() + 1).toString()) + '/' + this.addZero(n.getFullYear().toString());

      if (new Date(Number(dateTempYYYY), Number(dateTempMM) - 1, Number(dateTempDD)) > new Date()) {
        this.inFechaDesde = today;
      }
    }
    criterios_busqueda['fechaDesde'] = this.inFechaDesde;

    if (this.inFechaHasta === 'yyyy/mm/dd') {
      this.inFechaHasta = '';
    }
    if (this.inFechaHasta !== '') {
      const dateTempYYYY = this.inFechaHasta.split('/')[2];
      const dateTempMM = this.inFechaHasta.split('/')[1];
      const dateTempDD = this.inFechaHasta.split('/')[0];
      const n = new Date();
      const today = this.addZero(n.getDate().toString()) + '/' + this.addZero((n.getMonth() + 1).toString()) + '/' + this.addZero(n.getFullYear().toString());

      if (new Date(Number(dateTempYYYY), Number(dateTempMM) - 1, Number(dateTempDD)) > new Date()) {
        this.inFechaHasta = today;
      }
    }
    criterios_busqueda['fechaHasta'] = this.inFechaHasta;

    criterios_busqueda['rows'] = this.tbl_rows;
    criterios_busqueda['first'] = event.first ? event.first : 0;

    if (this.inResena.trim().split(' ').length > 5) {
      passed = false;
      errMsg = 'El máximo de palabras a buscar es 5';
    }

    this.documentoService.getNormasLegales(criterios_busqueda, this.userRol).subscribe(resp => {

      this.showProgressSpinnerTable = false;

      if (resp['data'] !== undefined) {
        // si encuentro normas legales

        for (let i = 0; i < resp['data'].length; i++) {
          
          resp['data'][i]['ABM'] = '   ';
          resp['data'][i]['resena_short'] =  resp['data'][i]['resena'].length > 55 ? resp['data'][i]['resena'].substring(0, 55) + '...' : resp['data'][i]['resena'];
          const f = new Date(resp['data'][i]['fecha_firma']);
          resp['data'][i]['fecha_firma'] =  this.addZero(f.getDate()) + '/' + this.addZero((f.getMonth() + 1)) + '/' + f.getFullYear();

          let nl_editable = false;

          if (this.tiposNormasEditables.length) {
            // si hay nl editables

            nl_editable = this.tiposNormasEditables.includes(resp['data'][i]['id_tipo_nl']);
          }
          resp['data'][i]['editable'] = nl_editable;
        } 
        // three spaces fastfix , one could get fired doing this
        this.tbl_rows_data = resp['data'];
        this.tbl_totalRecords = resp['data'][0] ? resp['data'][0]['total_records'] : 0;
      }
    });
  }

  cleanFilters() {

    this.inNumero = '';
    this.inFechaDesde = '';
    this.inFechaHasta = '';
    this.inResena = '';
    this.inCaracter = '';
    this.inVoz = '';
    this.tipoNormaLegalSelected = undefined;
    this.panel_collapsed = true;
  }

  addZero(nString) {
    if (parseInt(nString) < 10) {
      return '0' + nString;
    } else {
      return nString; 
    }
  }

  editNormaLegal(par_norma_legal) {

    this.normaService.setNormaLegalToUpdate(
      par_norma_legal['id'],
      par_norma_legal['id_tipo_nl'],
      par_norma_legal['numero']
    );
    this.router.navigate(['nueva']);
  }

  backToSearch() {

    this.showSearchComponents = true;
  }

  // applyFilters() {

  //   this.showSearchComponents = false;

  //   let passed = true;
  //   let errMsg = '';

  //   this.showFilterWarning = false;
  //   this.filterErrorMessage = '';

  //   if (this.inResena.trim().split(' ').length > 5) {
  //     passed = false;
  //     errMsg = 'El máximo de palabras a buscar es 5';
  //   }
  //   this.dataTable['filters']['idTipo'] = {'value': this.inTipo};
  //   this.dataTable['filters']['caracter'] = {'value': this.inCaracter};
  //   this.dataTable['filters']['numero'] = {'value': this.inNumero};
  //   this.dataTable['filters']['resena'] = {'value': this.inResena};
  //   this.dataTable['filters']['idVoz'] = {'value': this.inVoz};
  //   this.dataTable['filters']['fechaDesde'] = {'value': this.inFechaDesde};
  //   this.dataTable['filters']['fechaHasta'] = {'value': this.inFechaHasta};
  //   this.dataTable['filters']['ocr_text'] = {'value': this.inOCR};
  //   this.dataTable['first'] = 0;
  //   if (this.dataTable['filters']['fechaDesde']['value'] == 'yyyy/mm/dd') {
  //     this.dataTable['filters']['fechaDesde'] = '';
  //   }
  //   if (this.dataTable['filters']['fechaHasta']['value'] == 'yyyy/mm/dd') {
  //     this.dataTable['filters']['fechaHasta'] = '';
  //   }
  //   // gambling mofo schedule [8:03-8:07] [8:59-9:08]
  //   if (this.dataTable['filters']['fechaDesde']['value'].trim() != '') {
  //     const dateTempYYYY = this.dataTable['filters']['fechaDesde']['value'].split('/')[2];
  //     const dateTempMM = this.dataTable['filters']['fechaDesde']['value'].split('/')[1];
  //     const dateTempDD = this.dataTable['filters']['fechaDesde']['value'].split('/')[0];
  //     const n = new Date();
  //     const today = this.addZero(n.getDate().toString()) + '/' + this.addZero((n.getMonth() + 1).toString()) + '/' + this.addZero(n.getFullYear().toString());

  //     if (new Date(dateTempYYYY, dateTempMM - 1, dateTempDD) > new Date()) {

  //       this.inFechaDesde = today;
  //     }
  //   }

  //   if (this.dataTable['filters']['fechaHasta']['value'].trim() != '') {
  //     const dateTempYYYY = this.dataTable['filters']['fechaHasta']['value'].split('/')[2];
  //     const dateTempMM = this.dataTable['filters']['fechaHasta']['value'].split('/')[1];
  //     const dateTempDD = this.dataTable['filters']['fechaHasta']['value'].split('/')[0];
  //     const n = new Date();
  //     const today = this.addZero(n.getDate()) + '/' + this.addZero((n.getMonth() + 1).toString()) + '/' + this.addZero(n.getFullYear());

  //     if (new Date(dateTempYYYY, dateTempMM - 1, dateTempDD) > new Date()) {

  //       this.inFechaHasta = today;
  //     }
  //   }

  //   if (passed) {
  //     this.buscarNormasLegales({});
  //   } else {
  //     this.showFilterWarning = true;
  //     this.filterErrorMessage = errMsg;
  //   }
  // }
}
