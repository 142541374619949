import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MainGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const token = localStorage.getItem('access_token');

    let rta: boolean;

    if (token !== null) {
      // Si hay usuario login

      if (this.authService.checkTokenExpiry()) {
        // si token expirado

        localStorage.removeItem('access_token');
        this.router.navigate(['/logout']);
        rta = false;
      } else {
        rta = true;
      }
    } else {
      // Si no hay usuario login
      rta = true;
    }
    return rta;
  }
}
