import { Component, OnInit } from '@angular/core';
import {NormaService} from '../../services/norma.service';
import {AuthService} from '../../services/auth.service';
import {MessageService} from 'primeng/api';
import {UtilsService} from '../../services/utils.service';
import {DocumentoService} from '../../services/documento.service';
import {Router} from '@angular/router';
import { NormaLegal } from '../../models/norma-legal';

interface Voz {
  'id': string;
  'descricion': string;
  'habilitado': boolean;
}

interface tipoNormaPorOficina {
  'id_tipo_nl': string;
  'descripcion_tipo_nl': string;
  'habilitado_tipo_nl': boolean;
  'numero_requerido': boolean;
}

interface tipoNorma {
  'id': string;
  'descripcion': string;
  'habilitado': boolean;
}

interface Destino {
  'id': string;
  'descripcion': string;
  'email': string;
}

interface tipoNormaAfectada {
  'id': string;
  'descripcion': string;
  'habilitado': boolean;
}

interface Afectacion {
  'id': string;
  'descripcion': string;
  'habilitado': boolean;
}

interface normaAfectada {
  'id_norma_legal'?: string;
  'id_tipo_nl'?: string;
  'numero'?: string;
  'id_afectacion_nl'?: string;
  'detalle_afectacion'?: string;
  'descripcion_tipo_nl'?: string;
  'descripcion_afectacion_nl'?: string;
}

@Component({
  selector: 'app-nueva-publicacion',
  templateUrl: './nueva-publicacion.component.html',
  styleUrls: ['./nueva-publicacion.component.scss']
})
export class NuevaPublicacionComponent implements OnInit {

    caso_uso = '';
  userDataArea = '';
  nl_selected: NormaLegal = undefined;
  
  // norma legal
  
  id_norma_legal = '';
  id_tipo_nl = '';
  numNorma = '';
  numNorma_disabled = false;
  tipoNormasPorOficina: tipoNormaPorOficina[] = [];
  selectedTipoNormaPorOficina: tipoNormaPorOficina = undefined;
  fechaFirmaNorma = '';
  caracter = 'Individual';
  tipoVoces: Voz[] = [];
  selectedTipoVoz: Voz = undefined;
  normaResena = '';
  pdf_name = '';
  pdf_length = 0;
  pdf_details = '';

  // Norma legal afectada

  id_norma_legal_afectada = '';
  id_tipo_nl_afectada = '';
  tipo_nl_descripcion_afectada = '';
  numNormaAfectada = '';
  uo_descripcion_afectada = '';
  oficina_descripcion_afectada = '';
  fecha_firma_afectada = '';
  caracter_afectada = '';
  voz_nl_descripcion_afectada = '';

  tipoAfectaciones: Afectacion[] = [];
  selectedTipoAfectacionAfecta: Afectacion;
  selectedTipoAfectacionAfectada: Afectacion = undefined;
  detalleAfectacion = '';
  
  normasAfectadasList: normaAfectada[] = [];

  destinosList: Destino[] = [];
  selectedDestino: Destino = undefined;
  destinosOficinaNotif: Destino[] = [];

  file: any = null;

  show_seleccionar_pdf = true;

  cu_nl_afectada = 'new';
  orden_nl_a_editar = 0;
  disable_delete_nl_afectada_button = false;

  labelActionNlAfectadas = 'Agregar';

  show_dialogo_confirmar_publicacion = false;
  dialogo_processing_ocr = false;
  dialogo_ocr_content = '';
  dialogo_ocr_show_progress = true;
  user_saveNormaLegal = false;
  dialogo_processing_ocr_finished = false;
  dialogo_ocr_content_finished = '';

  norma_legal_object: any;
  cambio_pdf = true;

  selected_file: any;

  dlg_norma_legal_afectada = false;

  card_header_title = '';
  
  show_oficinas_a_notificar = false;

  constructor(
    private normaService: NormaService,
    private authService: AuthService,
    private messageService: MessageService,
    private utilsServices: UtilsService,
    private documentoService: DocumentoService,
    private router: Router) { }

  ngOnInit() {

    // limpio todos los componentes del form
    this.clearComponents();

    // obtengo datos de norma legal para modificar
    this.id_norma_legal = this.normaService.getNormaLegalToUpdate()['id_norma_legal'];
    this.id_tipo_nl = this.normaService.getNormaLegalToUpdate()['id_tipo_nl'];
    this.numNorma = this.normaService.getNormaLegalToUpdate()['numero'];

    // obengo el area del usuario logueado
    this.userDataArea = this.authService.getUserDataArea()[0];

    if (this.id_norma_legal === '') {
      // Si es una nueva norma legal

      this.card_header_title = 'Nueva Norma Legal';
      this.caso_uso = 'create';
      this.show_seleccionar_pdf = true;

      // Obtengo los tipos de normas legales que puede publicar la oficina del usuario logueado

      this.normaService.getOficinas(null, this.userDataArea).subscribe(data => {

        if (data['data'] != undefined) {

          const _id_oficina = data['data'][0]['id'];

          // obtengo los tipos de normas legales habilitados para esta oficina
          this.cargaTiposNormasPorOficina(this.caso_uso, _id_oficina);
        }
      });

      // Obtengo las voces
      this.cargaVoces(this.caso_uso);

      this.file = {
        'name' : '',
        'size' : 0
      };

    } else {
      // si estoy editando una norma legal

      this.card_header_title = 'Editando Norma Legal';

      // Obtengo la norma legal a modificar
      this.cargaNormaLegal();
    }

    // Obtengo los tipos de afectación de normas legales
    this.cargaTiposAfectacionNL();

    // Obtengo las oficinas a las que se notifica
    this.cargaOficinasNotif();
  }

  removeDestinoList(destino) {

    for (let i = 0; i < this.destinosOficinaNotif.length; i++) {
      if (destino['id'] == this.destinosOficinaNotif[i]['id']) {
        this.destinosOficinaNotif.splice(i, 1);
      }
    }

    // Renumero
    for (let i = 0; i < this.destinosOficinaNotif.length; i++) {

      this.destinosOficinaNotif[i]['orden'] = i + 1;
    }
  }

  pushDestinosList() {

    if (this.selectedDestino != undefined) {

      let _destino_existente = false;

      for (let i = 0; i < this.destinosOficinaNotif.length; i++) {

        if (this.selectedDestino['id'] == this.destinosOficinaNotif[i]['id']) {

          _destino_existente = true;
          break;
        }
      }

      if (!_destino_existente) {
        // si la norma legal afectada no está repetida

        const _orden = this.destinosOficinaNotif.length + 1;
        const oficina_notif: Destino = { 'id' : '', 'descripcion' : '', 'email' : ''};

        oficina_notif['orden'] = _orden;
        oficina_notif['id'] = this.selectedDestino['id'];
        oficina_notif['descripcion'] = this.selectedDestino['descripcion'];
        oficina_notif['email'] = this.selectedDestino['email'];
        this.destinosOficinaNotif.push(oficina_notif);
      } else {
        this.messageService.add({severity: 'warn', summary: '', detail: 'La Oficina a notificar ya fue agregada'});
      }
    }
  }

  cargaNormaLegal() {

    this.caso_uso = 'update';
    this.show_seleccionar_pdf = false;
    this.cambio_pdf = false;

    this.documentoService.getNormaLegal(this.id_norma_legal).subscribe(data => {

      const norma_legal = data['data'][0]['norma_legal'];

      const normas_legales_afectadas = data['data'][0]['normas_legales_afectadas'];
      const normas_legales_oficinas_notif = data['data'][0]['normas_legales_oficinas_notif'];

      this.normaService.getOficinas(null, this.userDataArea).subscribe(data => {

        if (data['data'] != undefined) {

          const _id_oficina = data['data'][0]['id'];

          this.cargaTiposNormasPorOficina(this.caso_uso, _id_oficina);
        }
      });

      this.numNorma = this.numNorma;
      this.fechaFirmaNorma = this.utilsServices.format_date(norma_legal['fecha_firma']);
      const caracter = norma_legal['caracter'];
      this.caracter = caracter.substring(0, 1).toUpperCase() + caracter.substring(1);

      // Obtengo las voces
      this.cargaVoces(this.caso_uso, norma_legal['id_voz_nl']);

      this.normaResena = norma_legal['resena'];
      this.pdf_name = norma_legal['pdf_name'];
      this.pdf_length = norma_legal['pdf_length'];
      this.pdf_details = this.pdf_name + ' (' + this.pdf_length + ')';

      // cargo la grilla de las normas legales afectadas por esta norma legal

      let _orden: number;
      let nl_afectada: normaAfectada = {};

      for (let i = 0; i < normas_legales_afectadas.length; i++) {

        _orden = i + 1;

        nl_afectada = {};

        nl_afectada['orden'] = _orden;
        nl_afectada['id_norma_legal'] = normas_legales_afectadas[i]['id_norma_legal_afectada'];
        nl_afectada['id_tipo_nl'] = normas_legales_afectadas[i]['id_tipo_nl'];
        nl_afectada['fecha_firma'] = this.utilsServices.format_date(normas_legales_afectadas[i]['fecha_firma']);
        nl_afectada['descripcion_tipo_nl'] = normas_legales_afectadas[i]['descripcion_tipo_nl'];
        nl_afectada['numero'] = normas_legales_afectadas[i]['numero'];
        nl_afectada['id_afectacion_nl'] = normas_legales_afectadas[i]['id_afectacion_nl'];
        nl_afectada['descripcion_afectacion_nl'] = normas_legales_afectadas[i]['descripcion_afectacion'];
        nl_afectada['detalle_afectacion'] = normas_legales_afectadas[i]['detalle_afectacion'];
        this.normasAfectadasList.push(nl_afectada);
      }

      // cargo la grilla de las oficinas a notificar

      let oficina_a_notificar: Destino = { 'id': '', 'descripcion': '', 'email': '' };

      for (let i = 0; i < normas_legales_oficinas_notif.length; i++) {

        _orden = i + 1;

        oficina_a_notificar = { 'id': '', 'descripcion': '', 'email': '' };

        oficina_a_notificar['orden'] = _orden;
        oficina_a_notificar['id'] = normas_legales_oficinas_notif[i]['id_oficina_notif'];
        oficina_a_notificar['descripcion'] = normas_legales_oficinas_notif[i]['descripcion_oficina_notif'];
        oficina_a_notificar['email'] = normas_legales_oficinas_notif[i]['email_oficina_notif'];
        this.destinosOficinaNotif.push(oficina_a_notificar);
      }

      const file_object = {};
      file_object['name'] = this.pdf_name;
      file_object['size'] = this.pdf_length;
      this.file = file_object;

      if (this.pdf_name !== '') {
        this.show_seleccionar_pdf = false;
      } else {
        this.show_seleccionar_pdf = true;
      }
    });
  }

  cargaTiposNormasPorOficina(par_caso_uso: string, par_id_oficina: string) {

    this.normaService.getTiposNormasPorOficina(par_id_oficina).subscribe(data => {

      if (data['data'] !== undefined) {

        const obj = data['data'];
        this.tipoNormasPorOficina = [...obj];

        if (par_caso_uso === 'update') {
          this.selectedTipoNormaPorOficina = this.tipoNormasPorOficina.find(x => x.id_tipo_nl === this.id_tipo_nl);

          if (this.selectedTipoNormaPorOficina.numero_requerido) {
            // si para el tipo de norma legal el número es requerido
            this.numNorma_disabled = false;
          } else {
            // si para el tipo de norma legal el número no es requerido
            this.numNorma = '';
            this.numNorma_disabled = true;
          }
        }
      }
    });
  }

  cargaVoces(par_caso_uso: string, par_id_voz_nl?: string) {

    const tipo_caracter = this.caracter == 'Individual' ? 'i' : 'g';
    this.normaService.getVoces(null, null, tipo_caracter).subscribe(data => {
      if (data['data'] != undefined) {
        const obj = data['data'];
        this.tipoVoces = [...obj];
        if (par_caso_uso == 'update') {
          this.selectedTipoVoz = this.tipoVoces.find(x => x.id == par_id_voz_nl);
        }
      }
    });
  }

  cargaTiposAfectacionNL() {
    this.normaService.getAfectaciones().subscribe(data => {
      if (data['data'] != undefined) {
        const obj = data['data'];
        this.tipoAfectaciones = [...obj];
      }
    });
  }

  cargaOficinasNotif() {
    this.normaService.getOficinasNotif(null, null).subscribe(data => {
      if (data['data'] != undefined) {
        const obj = data['data'];
        this.destinosList = [...obj];
      }
    });
  }

  addNormaLegalAfectada() {

    if (this.id_norma_legal_afectada !== '' && this.selectedTipoAfectacionAfectada !== undefined) {
      // Si se ha seleccionado una norma legal valida y se ha seleccionado un tipo de afectación

      const _fecha_firma_nl = new Date(this.utilsServices.format_date_for_insert(this.fechaFirmaNorma));
      const _fecha_firma_nl_afectada = new Date(this.utilsServices.format_date_for_insert(this.fecha_firma_afectada));

      if (_fecha_firma_nl < _fecha_firma_nl_afectada) {
        // si la fecha de firma de la nl es < a la fecha de firma de la nl afectada

        this.messageService.add({severity: 'warn', summary: '', detail: 'La fecha de firma de la Norma Legal debe ser igual o posterior a la de sus Normas Legales afectadas'});
      } else {
        // si la fecha de firma de la nl es >= a la fecha de firma de la nl afectada

        let _norma_legal_existente = false;

        for (let i = 0; i < this.normasAfectadasList.length; i++) {

          if (this.id_norma_legal_afectada === this.normasAfectadasList[i]['id_norma_legal'] &&
              this.selectedTipoAfectacionAfectada.id === this.normasAfectadasList[i]['id_afectacion_nl'] &&
              (this.cu_nl_afectada === 'new' ? true : i !== this.orden_nl_a_editar)) {

            _norma_legal_existente = true;
            break;
          }
        }

        if (!_norma_legal_existente) {
          // si la norma legal afectada no está repetida

          if (this.cu_nl_afectada === 'new') {
            // Si estoy agregando una norma legal afectada

            const _orden = this.normasAfectadasList.length + 1;

            const nl_afectada: normaAfectada = {};

            nl_afectada['orden'] = _orden;
            nl_afectada['id_norma_legal'] = this.id_norma_legal_afectada;
            nl_afectada['fecha_firma'] = this.fecha_firma_afectada;
            nl_afectada['id_tipo_nl'] = this.id_tipo_nl_afectada;
            nl_afectada['descripcion_tipo_nl'] = this.tipo_nl_descripcion_afectada;
            nl_afectada['numero'] = this.numNormaAfectada;
            nl_afectada['id_afectacion_nl'] = this.selectedTipoAfectacionAfectada.id;
            nl_afectada['descripcion_afectacion_nl'] = this.selectedTipoAfectacionAfectada.descripcion;
            nl_afectada['detalle_afectacion'] = this.detalleAfectacion;
            this.normasAfectadasList.push(nl_afectada);
          } else {
            // Si estoy editando una norma legal afectada

            this.normasAfectadasList[this.orden_nl_a_editar]['id_norma_legal'] = this.id_norma_legal_afectada;
            this.normasAfectadasList[this.orden_nl_a_editar]['id_tipo_nl'] = this.id_tipo_nl_afectada;
            this.normasAfectadasList[this.orden_nl_a_editar]['fecha_firma'] = this.fecha_firma_afectada;
            this.normasAfectadasList[this.orden_nl_a_editar]['descripcion_tipo_nl'] = this.tipo_nl_descripcion_afectada;
            this.normasAfectadasList[this.orden_nl_a_editar]['numero'] = this.numNormaAfectada;
            this.normasAfectadasList[this.orden_nl_a_editar]['id_afectacion_nl'] = this.selectedTipoAfectacionAfectada.id;
            this.normasAfectadasList[this.orden_nl_a_editar]['descripcion_afectacion_nl'] = this.selectedTipoAfectacionAfectada.descripcion;
            this.normasAfectadasList[this.orden_nl_a_editar]['detalle_afectacion'] = this.detalleAfectacion;
          }

          this.clearNormaLegalAfectada();

        } else {
          this.messageService.add({severity: 'warn', summary: '', detail: 'La Norma Legal y tipo de afectación ya fue agregada'});
        }
      }
    } else {
      this.messageService.add({severity: 'warn', summary: '', detail: 'Complete los datos de la Norma Legal afectada'});
    }
  }

  cancelNormaLegalAfectada() {

    this.clearNormaLegalAfectada();
  }

  editNormaLegalAfectada(par_id_norma_legal: string, par_id_afectacion_nl: string) {

    for (let i = 0; i < this.normasAfectadasList.length; i++) {

      if (par_id_norma_legal === this.normasAfectadasList[i]['id_norma_legal'] && par_id_afectacion_nl === this.normasAfectadasList[i]['id_afectacion_nl']) {
        // si estoy sobre la nl afectada a modificar

        this.disable_delete_nl_afectada_button = true;
        this.cu_nl_afectada = 'edit';
        this.labelActionNlAfectadas = 'Actualizar';

        this.orden_nl_a_editar = this.normasAfectadasList[i]['orden'] - 1;

        // obtengo la norma legal seleccionada

        this.normaService.getNormaLegal(this.normasAfectadasList[i]['id_tipo_nl'], this.normasAfectadasList[i]['numero']).subscribe(data => {

          this.id_norma_legal_afectada = data['data'][0]['id'];
          this.tipo_nl_descripcion_afectada = data['data'][0]['descripcion_tipo_nl'];
          this.numNormaAfectada = data['data'][0]['numero'];
          this.uo_descripcion_afectada = data['data'][0]['descripcion_uo'];
          this.oficina_descripcion_afectada = data['data'][0]['descripcion_oficina'];
          this.fecha_firma_afectada = this.utilsServices.format_date(data['data'][0]['fecha_firma']);
          this.caracter_afectada = data['data'][0]['caracter'];
          this.voz_nl_descripcion_afectada = data['data'][0]['descripcion_voz_nl'];

          this.detalleAfectacion = this.normasAfectadasList[i]['detalle_afectacion'];
        });

        this.selectedTipoAfectacionAfectada = this.tipoAfectaciones.find(x => x.id === this.normasAfectadasList[i]['id_afectacion_nl']);

        break;
      }
    }
  }

  deleteNormaLegalAfectada(par_id_norma_legal: string, par_id_afectacion_nl: string) {

    // Borro la nl afectada seleccionada
    for (let i = 0; i < this.normasAfectadasList.length; i++) {

      if (this.normasAfectadasList[i]['id_norma_legal'] === par_id_norma_legal && this.normasAfectadasList[i]['id_afectacion_nl'] === par_id_afectacion_nl) {

        this.normasAfectadasList.splice(i, 1);
      }
    }

    // Renumero las nl
    for (let i = 0; i < this.normasAfectadasList.length; i++) {

      this.normasAfectadasList[i]['orden'] = i + 1;
    }
  }

  clearComponents() {

    const _today = new Date();
    const _dia = ('00' + (_today.getDate()).toString()).slice(-2);
    const _mes = ('00' + (_today.getMonth() + 1).toString()).slice(-2);
    const _anio = _today.getFullYear();

    this.id_norma_legal = '';
    this.id_tipo_nl = '';
    this.selectedTipoNormaPorOficina = undefined;
    this.numNorma = '';
    this.fechaFirmaNorma = '';
    this.caracter = 'Individual';
    this.selectedTipoVoz = undefined;
    this.normaResena = '';
    this.clearNormaLegalAfectada();
    this.normasAfectadasList = [];
    this.destinosOficinaNotif = [];
    this.show_dialogo_confirmar_publicacion = false;
    this.norma_legal_object = {};
    this.show_seleccionar_pdf = true;
    this.file = null;
    this.pdf_name = '';
    this.pdf_length = 0;
    this.pdf_details = '';
  }

  getNormaLegalAfectada() {

    // if (this.selectedTipoNormaAfectada !== undefined && this.numNormaAfectada !== '' ) {
    if (this.id_tipo_nl_afectada !== '' && this.numNormaAfectada !== '' ) {
      // Si se ha ingresado el tipo de norma legal y el Numero

      this.normaService.getNormaLegal(this.id_tipo_nl_afectada, this.numNormaAfectada).subscribe(data => {

        if (data['error_code'] !== undefined) {

          this.clearNormaLegalAfectada();
          this.messageService.add({severity: 'warn', summary: '', detail: data['message_description']});
        } else {

          if (data['data'].length === 0) {

            this.clearNormaLegalAfectada();
            this.messageService.add({severity: 'warn', summary: '', detail: 'Norma legal inexistente'});
          } else {

            const nl_afectada = data['data'][0];

            this.id_norma_legal_afectada = nl_afectada['id'];
            this.uo_descripcion_afectada = nl_afectada['descripcion_uo'];
            this.oficina_descripcion_afectada = nl_afectada['descripcion_oficina'];
            this.fecha_firma_afectada = this.utilsServices.format_date(nl_afectada['fecha_firma']);
            this.caracter_afectada = nl_afectada['caracter'];
            this.voz_nl_descripcion_afectada = nl_afectada['descripcion_voz_nl'];
          }
        }
      });
    }
  }

  clearNormaLegalAfectada() {

    this.id_norma_legal_afectada = '';
    this.id_tipo_nl_afectada = '';
    this.tipo_nl_descripcion_afectada = '';
    this.numNormaAfectada = '';
    this.uo_descripcion_afectada = '';
    this.oficina_descripcion_afectada = '';
    this.fecha_firma_afectada = '';
    this.caracter_afectada = '';
    this.voz_nl_descripcion_afectada = '';
    this.selectedTipoAfectacionAfectada = undefined;
    this.detalleAfectacion = '';
    this.disable_delete_nl_afectada_button = false;

    this.labelActionNlAfectadas = 'Agregar';
    this.disable_delete_nl_afectada_button = false;
    this.cu_nl_afectada = 'new';
  }

  saveNormaLegal() {

    this.dialogo_processing_ocr = true;
    this.dialogo_ocr_show_progress = true;
    this.dialogo_ocr_content = 'Guardando la Norma Legal ...';

    const obj_norma_legal = {};

    if (this.caso_uso === 'create') {
      obj_norma_legal['id_norma_legal'] = '';
    } else {
      obj_norma_legal['id_norma_legal'] = this.id_norma_legal;
    }
    obj_norma_legal['descripcion_oficina'] = this.authService.getUserDataArea();
    obj_norma_legal['username'] = this.authService.getUserDataUserName();
    obj_norma_legal['id_tipo_nl'] = this.selectedTipoNormaPorOficina['id_tipo_nl'];
    obj_norma_legal['numero'] = this.numNorma;
    obj_norma_legal['fecha_firma'] = this.utilsServices.format_date_for_insert(this.fechaFirmaNorma);
    obj_norma_legal['caracter'] = this.caracter.toLowerCase();
    obj_norma_legal['id_voz_nl'] = this.selectedTipoVoz['id'];
    obj_norma_legal['resena'] = this.normaResena;
    obj_norma_legal['nl_afectadas'] = this.normasAfectadasList;
    obj_norma_legal['oficinas_notif'] = this.destinosOficinaNotif;
    obj_norma_legal['cambio_pdf'] = this.cambio_pdf;
    obj_norma_legal['file'] = this.file;

    this.normaService.saveNormaLegal(obj_norma_legal).subscribe(data => {

      if (data['error_code'] !== undefined) {
        // nl no guardada debido a un error

        this.dialogo_processing_ocr = false;
        this.messageService.add({severity: 'warn', summary: '', detail: data['message_description']});
      } else {
        // nl guardada

        if (this.destinosOficinaNotif.length > 0) {
          // si hay oficinas para notificar

          let obj_to_email = {};
          const list_to_email = [];

          for (let i = 0; i < this.destinosOficinaNotif.length; i++) {

            if (this.destinosOficinaNotif[i]['email'] != '') {
              obj_to_email = {};
              obj_to_email['id_norma_legal'] = data['id_norma_legal'];
              obj_to_email['id_oficina_notif'] = this.destinosOficinaNotif[i]['id'];
              list_to_email.push(obj_to_email);
            }
          }

          this.normaService.sendEmail(list_to_email).subscribe(data1 => {

            if (data1['code'] === undefined) {

              console.log('se produjo un error al enviar los mails a as oficinas de destino');

              this.dialogo_processing_ocr = false;
              this.messageService.add({severity: 'warn', summary: '', detail: 'Se produjo un error al enviar los mails a las Oficinas de destino'});
            }
          });
        }

        this.dialogo_processing_ocr = false;

        if (this.cambio_pdf) {
          
          this.dialogo_processing_ocr = true;
          this.dialogo_ocr_show_progress = true;
          this.dialogo_ocr_content = 'Norma Legal guardada! Procesando el documento para extraer el texto';

          this.normaService.triggerOCRProcessing(data['id_norma_legal']).subscribe(response => {

            response = response['data'];

            if (response['status'] === 'success') {
              // si el proceso de extracción finalizo ok

              this.dialogo_processing_ocr = false;
              this.dialogo_processing_ocr_finished = true;
              this.dialogo_ocr_content_finished = 'El proceso ha finalizado exitosamente!';

            } else {
              // si el proceso de extracción no finalizo ok

              this.dialogo_processing_ocr = false;
              this.dialogo_processing_ocr_finished = true;
              this.dialogo_ocr_content_finished = 'Documento guardado, no fue posible extraer el texto del archivo.';
            }
          });
        } else {
          this.final();
        }
      }
    });
  }

  validateNormaLegal() {

    let _error = false;

    if (!this.selectedTipoNormaPorOficina) {
      // si el usuario no ha seleccionado un tipo de norma legal
      _error = true;
      this.messageService.add({severity: 'warn', summary: '', detail: 'Indique el Tipo de la Norma Legal a publicar'});
    } else {
      // si el usuario ha seleccionado un tipo de norma legal

      if (this.selectedTipoNormaPorOficina.numero_requerido) {
        // si para el tipo de norma legal el número es requerido
  
        if (this.numNorma === '') {
          _error = true;
          this.messageService.add({severity: 'warn', summary: '', detail: 'Ingrese el número de la Norma Legal a publicar'});
        }
      }
    }

    if (!this.utilsServices.validate_date(this.fechaFirmaNorma)) {
      _error = true;
      this.messageService.add({severity: 'warn', summary: '', detail: 'Ingrese la fecha de la Norma Legal a publicar'});
    }

    if (this.caracter !== 'Individual' && this.caracter !== 'General') {
      _error = true;
      this.messageService.add({severity: 'warn', summary: '', detail: 'Seleccione el carácter de la Norma Legal a publicar'});
    }

    if (this.selectedTipoVoz === undefined) {
      _error = true;
      this.messageService.add({severity: 'warn', summary: '', detail: 'Indique la Voz de la Norma Legal a publicar'});
    }

    if (this.normaResena == '') {
      _error = true;
      this.messageService.add({severity: 'warn', summary: '', detail: 'Ingrese el tema de la Norma Legal a publicar'});
    }

    if (!this.file || this.file['name'] === '') {
      _error = true;
      this.messageService.add({severity: 'warn', summary: '', detail: 'Debe seleccionar el documento PDF de la Norma Legal'});
    }

    if (!_error) {

      const obj_norma_legal = {};

      obj_norma_legal['descripcion_oficina'] = this.authService.getUserDataArea();
      obj_norma_legal['username'] = this.authService.getUserDataUserName();
      obj_norma_legal['id_tipo_nl'] = this.selectedTipoNormaPorOficina['id_tipo_nl'];
      obj_norma_legal['numero'] = this.numNorma;
      obj_norma_legal['fecha_firma'] = this.utilsServices.format_date_for_insert(this.fechaFirmaNorma);
      obj_norma_legal['caracter'] = this.caracter;
      obj_norma_legal['id_voz_nl'] = this.selectedTipoVoz['id'];
      obj_norma_legal['resena'] = this.normaResena;
      obj_norma_legal['nl_afectadas'] = this.normasAfectadasList;
      obj_norma_legal['oficinas_a_notificar'] = this.destinosOficinaNotif;
      obj_norma_legal['file'] = this.file;

      this.norma_legal_object = obj_norma_legal;
      this.show_dialogo_confirmar_publicacion = true;
    }
  }

  cancelNormaLegal() {

    this.router.navigate(['/normas']);
  }

  confirmaNormaLegal(par_publica_nl: boolean) {

    this.show_dialogo_confirmar_publicacion = false;
    this.user_saveNormaLegal = par_publica_nl;
  }

  onHideDialog() {

    if (this.user_saveNormaLegal) {
      this.saveNormaLegal();
    }
  }

  setFile(evt) {

    this.file = evt.files[0];
  }

  removeFile(evt) {
    this.file = null;
  }

  quitarPDF() {
    this.cambio_pdf = true;
    this.pdf_name = '';
    this.pdf_length = 0;
    this.pdf_details = '';
    this.file = null;
    this.show_seleccionar_pdf = true;
  }

  final() {
    this.router.navigate(['/normas']);
  }

  clickTipoCaracter() {

    // Obtengo las voces
    this.cargaVoces(this.caso_uso);
  }

  onChangeTipoNL() {

    if (this.selectedTipoNormaPorOficina.numero_requerido) {
      // si para el tipo de norma legal seleccionado el número es requerido 

      this.numNorma_disabled = false;
    } else {
      // si para el tipo de norma legal seleccionado el número no es requerido

      this.numNorma = '';
      this.numNorma_disabled = true;
    }
  }

  buscarNLAfectada() {
    this.dlg_norma_legal_afectada = true;
  }

  receiveNLSelected($event) {

    this.nl_selected = $event;
    this.dlg_norma_legal_afectada = false;
    this.showNormaLegalAfectada();
  }

  showNormaLegalAfectada() {

    const fecha_firma = (new Date(this.utilsServices.format_date_for_insert(this.nl_selected['fecha_firma']))).toLocaleDateString('en-GB')

    this.id_norma_legal_afectada = this.nl_selected['id'];
    this.id_tipo_nl_afectada = this.nl_selected['id_tipo_nl'];
    this.tipo_nl_descripcion_afectada = this.nl_selected['descripcion_tipo_nl'];
    this.numNormaAfectada = this.nl_selected['numero'];
    this.uo_descripcion_afectada = this.nl_selected['descripcion_uo'];
    this.oficina_descripcion_afectada = this.nl_selected['descripcion_oficina'];
    this.fecha_firma_afectada = fecha_firma;
    this.caracter_afectada = this.nl_selected['caracter'];
    this.voz_nl_descripcion_afectada = this.nl_selected['descripcion_voz_nl'];
  }
}
