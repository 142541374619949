import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { MainComponent } from './components/main/main.component';
import { HttpClientModule } from '@angular/common/http';

import {CardModule} from 'primeng/card';
import {TableModule} from 'primeng/table';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import {DialogModule} from 'primeng/dialog';

import {FileUploadModule} from 'primeng/fileupload';
import {InputMaskModule} from 'primeng/inputmask';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {ToastModule} from 'primeng/toast';
import {KeyFilterModule} from 'primeng/keyfilter';
import {RadioButtonModule} from 'primeng/radiobutton';
import {ProgressBarModule} from 'primeng/progressbar';

import {MessageService} from 'primeng/api';
import {ConfirmationService} from 'primeng/api'
import {ProgressSpinnerModule} from 'primeng/progressspinner';

import {MenubarModule} from 'primeng/menubar';
import {PanelModule} from 'primeng/panel';
import {ScrollPanelModule} from 'primeng/scrollpanel';

import {ConfigsService} from './services/configs.service';
import {AuthService} from './services/auth.service';
import {ErrorService} from './services/error.service';
import {UtilsService} from './services/utils.service';
import {WsService} from './services/ws.service'

import { httpInterceptorProviders } from './interceptors/index';
import { LogoutComponent } from './components/logout/logout.component';

//import { HashLocationStrategy, LocationStrategy } from '@angular/common';

// MATERIAL
import {MatDialogModule,MatDialogRef} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { TablaNormasComponent } from './components/tabla-normas/tabla-normas.component';
import {TablaNormasDialogComponent} from './components/tabla-normas-dialog/tabla-normas-dialog.component'
import {NormaDetailComponent} from './components/norma-detail/norma-detail.component'
import { NuevaPublicacionComponent } from './components/nueva-publicacion/nueva-publicacion.component';
import { DocViewerComponent } from './components/doc-viewer/doc-viewer.component';
import { ConfirmaNormaComponent } from './components/confirma-norma/confirma-norma.component';
import { BuscaNlAfectadaComponent } from './components/busca-nl-afectada/busca-nl-afectada.component';

import { PrimaryCrudsModule } from './modules/primary-cruds/primary-cruds.module';

// hot and sexy websockets
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

const config: SocketIoConfig = { url: 'http://localhost:5466', options: {} };
// import { PdfViewerModule } from 'ng2-pdf-viewer';

// import {StoreModule} from "@ngrx/store";

// import { NgReduxModule, NgRedux } from '@angular-redux/store';
// import { rootReducer } from './reducers';
// interface IAppState {
//   /* ... */
// }

// import {operationsReducer} from "./common/operations";//import the reducer


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NotfoundComponent,
    MainComponent,
    LogoutComponent,
    TablaNormasComponent,
    NuevaPublicacionComponent,
    TablaNormasDialogComponent,
    DocViewerComponent,
    NormaDetailComponent,
    ConfirmaNormaComponent,
    BuscaNlAfectadaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbCollapseModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TableModule,
    DropdownModule,
    FormsModule,
    InputTextModule,
    RadioButtonModule,
    PdfJsViewerModule,
    DialogModule,
    FileUploadModule,
    InputMaskModule,
    InputTextareaModule,
    MatDialogModule,
    CardModule,
    ToastModule,
    KeyFilterModule,
    MatButtonModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    PrimaryCrudsModule,
    SocketIoModule.forRoot(config),
    MenubarModule,
    PanelModule,
    ScrollPanelModule,
    //RouterModule.forRoot(routes, { useHash: true })
    // PdfViewerModule
  //  StoreModule.forRoot({ operations: operationsReducer }) //provideStore accepts an object with reducers.
  ],
  entryComponents: [
    TablaNormasDialogComponent,
    DocViewerComponent,
    NormaDetailComponent
  ],

  providers: [
    httpInterceptorProviders,
    ConfigsService,
    MessageService,
    AuthService,
    ErrorService,
    UtilsService,
    ConfirmationService,
    WsService,
    { provide: MatDialogRef, useValue: {} }
    //{ provide: LocationStrategy, useClass: HashLocationStrategy} 
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  // constructor(ngRedux: NgRedux<IAppState>) {
  //     ngRedux.configureStore(rootReducer, {}, [createLogger()]);
  //   }

}
