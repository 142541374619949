import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';

// primeng 

import {TableModule} from 'primeng/table';

// models

import { TipoNormaLegal } from '../../models/tipo-norma-legal';
import { Caracter } from '../../models/caracter';
import { VozNormaLegal } from '../../models/voz-norma-legal';
import { NormaLegal } from '../../models/norma-legal';

// services

import {DocumentoService} from '../../services/documento.service';
import {NormaService} from '../../services/norma.service';
import {AuthService} from '../../services/auth.service';

export interface LazyLoadEvent {
  first?: number;
  rows?: number;
  sortField?: string;
  sortOrder?: number;
  filters?: {[s: string]: FilterMetadata; };
}

export interface FilterMetadata {
  value?: string;
  matchMode?: string;
}

@Component({
  selector: 'app-busca-nl-afectada',
  templateUrl: './busca-nl-afectada.component.html',
  styleUrls: ['./busca-nl-afectada.component.scss']
})
export class BuscaNlAfectadaComponent implements OnInit {

  @ViewChild('tbl_nl_afectadas')
  tbl_nl_afectadas: TableModule;

  @Output() selectNLEvent = new EventEmitter<NormaLegal>();

  userRol = '';

  dpdn_tipos_nl: TipoNormaLegal[] = [];
  dpdn_tipo_nl_selected: TipoNormaLegal = undefined;
  in_numero_nl = '';
  in_tema_nl = '';
  in_fecha_desde = '';
  in_fecha_hasta = '';
  dpdn_caracteres: Caracter[] = [];
  dpdn_caracter_selected: Caracter = undefined;
  dpdn_voces: VozNormaLegal[] = [];
  dpdn_voz_selected: VozNormaLegal = undefined;

  tbl_columnas = [];
  tbl_normas_legales: NormaLegal[] = [];
  tbl_norma_legal_selected: NormaLegal = null;
  tbl_loading = false;
  tbl_first = 0;
  tbl_rows = 10;
  tbl_totalRecords = 0;
  tbl_paginatorPosition = 'bottom';

  btn_select_disabled = false;

  constructor(
    private normaService: NormaService,
    private authService: AuthService,
    private documentoService: DocumentoService
  ) { }

  ngOnInit() {

    this.tbl_loading = true;
    this.btn_select_disabled = true;

    // obtengo el rol del usuario login o PUBLIC si es un usuario publico
    this.userRol = this.authService.getUserRol();

    // columnas de la table

    this.tbl_columnas = [
      { field: 'descripcion_tipo_nl', header: 'Tipo de Norma Legal' },
      { field: 'numero', header: 'Número' },
      { field: 'fecha_firma', header: 'Fecha' },
      { field: 'descripcion_voz_nl', header: 'Voz' },
      { field: 'resena', header: 'Tema' }
    ];

    // cargo tipos de normas legales

    this.normaService.getTiposNormas().subscribe(data => {
      
      if (data['data'] !== undefined) {

        this.dpdn_tipos_nl = [...data['data']];
      }
    });

    // cargo caracter de normas legales

    this.dpdn_caracteres.push( { id: 'individual', descripcion: 'Individual' } );
    this.dpdn_caracteres.push( { id: 'general', descripcion: 'General' } );

    // cargo voces

    this.normaService.getVoces().subscribe(data => {
      
      if (data['data'] !== undefined) {

        this.dpdn_voces = [...data['data']];
      }
    });

    const event = {
      'first': this.tbl_first,
      'rows': this.tbl_rows
    };

    this.clearComponents();
    this.buscarNL(event);
  }

  resetDT(dt: TableModule) {

    dt['first'] = 0;
    this.buscarNL({});
  }

  clearComponents() {
    
    this.dpdn_tipo_nl_selected = undefined;
    this.in_numero_nl = '';
    this.in_tema_nl = '';
    this.in_fecha_desde = '';
    this.in_fecha_hasta = '';
    this.dpdn_caracter_selected = undefined;
    this.dpdn_voz_selected = undefined;
  }

  buscarNL(event: LazyLoadEvent) {

    this.tbl_rows = event.rows ? event.rows : this.tbl_rows;
    this.tbl_loading = true;

    if (this.in_fecha_desde === 'yyyy/mm/dd') {
      this.in_fecha_desde = '';
    }
    if (this.in_fecha_desde !== '') {
      const dateTempYYYY = this.in_fecha_desde.split('/')[2];
      const dateTempMM = this.in_fecha_desde.split('/')[1];
      const dateTempDD = this.in_fecha_desde.split('/')[0];
      const n = new Date();
      const today = this.addZero(n.getDate().toString()) + '/' + this.addZero((n.getMonth() + 1).toString()) + '/' + this.addZero(n.getFullYear().toString());

      if (new Date(Number(dateTempYYYY), Number(dateTempMM) - 1, Number(dateTempDD)) > new Date()) {
        this.in_fecha_desde = today;
      }
    }
    
    if (this.in_fecha_hasta === 'yyyy/mm/dd') {
      this.in_fecha_hasta = '';
    }
    if (this.in_fecha_hasta !== '') {
      const dateTempYYYY = this.in_fecha_hasta.split('/')[2];
      const dateTempMM = this.in_fecha_hasta.split('/')[1];
      const dateTempDD = this.in_fecha_hasta.split('/')[0];
      const n = new Date();
      const today = this.addZero(n.getDate().toString()) + '/' + this.addZero((n.getMonth() + 1).toString()) + '/' + this.addZero(n.getFullYear().toString());
      
      if (new Date(Number(dateTempYYYY), Number(dateTempMM) - 1, Number(dateTempDD)) > new Date()) {
        this.in_fecha_hasta = today;
      }
    }
    
    const criterios_busqueda = {};
    
    criterios_busqueda['idTipo'] = this.dpdn_tipo_nl_selected !== undefined && this.dpdn_tipo_nl_selected !== null ? this.dpdn_tipo_nl_selected['id'] : '';
    criterios_busqueda['numero'] = this.in_numero_nl;
    criterios_busqueda['resena'] = this.in_tema_nl;
    criterios_busqueda['fechaDesde'] = this.in_fecha_desde;
    criterios_busqueda['fechaHasta'] = this.in_fecha_hasta;
    criterios_busqueda['caracter'] = this.dpdn_caracter_selected !== undefined && this.dpdn_caracter_selected !== null ? this.dpdn_caracter_selected['id'] : '';
    criterios_busqueda['idVoz'] = this.dpdn_voz_selected !== undefined && this.dpdn_voz_selected !== null ? this.dpdn_voz_selected['id'] : '';
    criterios_busqueda['rows'] = this.tbl_rows;
    criterios_busqueda['first'] = event.first ? event.first : 0;

    this.documentoService.getNormasLegales(criterios_busqueda, this.userRol).subscribe(data => {    

      const list = data['data'];

      for (let i = 0; i < list.length; i++) {

        list[i]['resena'] = list[i]['resena'].substring(0, 55);

        const f = new Date(list[i]['fecha_firma']);
        list[i]['fecha_firma'] = this.addZero(f.getDate()) + '/' + this.addZero((f.getMonth() + 1)) + '/' + f.getFullYear();
      }

      this.tbl_totalRecords = list.length ? list[0]['total_records'] : 0;
      this.tbl_normas_legales = list;
      this.btn_select_disabled = list.lenght;
      this.tbl_loading = false;
    })
  }

  selectNL() {

    if (this.tbl_norma_legal_selected) {
      this.selectNLEvent.emit(this.tbl_norma_legal_selected);
    }
  }

  addZero(nString) {
    if (parseInt(nString) < 10) {
      return '0' + nString;
    } else {
      return nString; 
    }
  }

  estiloColumnas(par_tipo: string, par_columna: string) {
    let estilo = '';

    if (par_columna === 'descripcion_tipo_nl') {
      estilo = par_tipo === 'head' ? 'descripcion_tipo_nl_head_style' : 'descripcion_tipo_nl_style';
    } else if (par_columna === 'numero') {
      estilo = par_tipo === 'head' ? 'numero_head_style' : 'numero_style';
    } else if (par_columna === 'fecha_firma') {
      estilo = par_tipo === 'head' ? 'fecha_firma_head_style' : 'fecha_firma_style';
    } else if (par_columna === 'descripcion_voz_nl') {
      estilo = par_tipo === 'head' ? 'descripcion_voz_nl_style' : 'descripcion_voz_nl_style';
    } else if (par_columna === 'resena') {
      estilo = par_tipo === 'head' ? 'resena_head_style' : 'resena_style';
    }
    return estilo;
  }
}
