import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  validate_date(par_date: string) {

    let fecha_valida : boolean = true;
    const dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

    if (dateformat.test(par_date)) {

      const pdate = par_date.split('/');
      var dd = parseInt(pdate[0]);
      var mm  = parseInt(pdate[1]);
      var yy = parseInt(pdate[2]);

      // Create list of days of a month [assume there is no leap year by default]
      var ListofDays = [31,28,31,30,31,30,31,31,30,31,30,31];

      if (mm==1 || mm>2) {
        if (dd>ListofDays[mm-1]) {
          fecha_valida = false;
        }
      }

      if (mm==2) {
        var lyear = false;
        if ( (!(yy % 4) && yy % 100) || !(yy % 400)) {
          lyear = true;
        }
        if ((lyear==false) && (dd>=29)) {
          fecha_valida = false;
        }
        if ((lyear==true) && (dd>29)) {
          fecha_valida = false;
        }
      }
    } else {
      fecha_valida = false;
    }
    return fecha_valida;
  }

  format_date_for_insert(par_date: string) {

    const date_arr = par_date.split('/');
    // aaaa/mm/dd
    return (date_arr[2] + '/' + date_arr[1]  + '/' + date_arr[0]);
  }

  format_date(par_date: string, par_separator?: string, par_type_return?: string) {

    let _return = '';

    if (par_separator === undefined || (par_separator !== '/' && par_separator !== '-')) {
      par_separator = '/';
    }

    if (par_type_return === undefined || ( par_type_return !== '' && par_type_return !== 'ISO')) {
      par_type_return = '';
    }

    if (par_date === undefined) {

      if (par_separator === '/') {
        _return = 'dd/mm/yyyy';
      } else {
        _return = 'dd-mm-yyyy';
      }
    } else {

      const date_ = new Date(par_date);
      const dia = ('00' + (date_.getDate()).toString()).slice(-2);
      const mes = ('00' + (date_.getMonth() + 1).toString()).slice(-2);
      const anio = date_.getFullYear();

      if (par_separator === '/') {
        _return = dia + '/' + mes + '/' + anio;
      } else {
        if (par_type_return === 'ISO') {
          _return = anio + '-' + mes + '-' + dia;
        } else {
          _return = dia + '-' + mes + '-' + anio;
        }
      }
    }
    return _return;
  }
}
