import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import { NormaService } from '../../services/norma.service'
import { CrudsService } from '../../services/cruds.service'

import { VozNormaLegal } from '../../models/voz-norma-legal'
import { TipoCaracter } from '../../models/tipo-caracter'

@Component({
  selector: 'app-voz-norma-legal',
  templateUrl: './voz-norma-legal.component.html',
  styleUrls: ['./voz-norma-legal.component.scss']
})
export class VozNormaLegalComponent implements OnInit {

  caso_uso: string = 'new'
  btn_aceptar_lbl: string = ''
  btn_aceptar_style: string = ''
  mensaje_eliminacion: string = '¿Confirma la elminación de esta Voz de Norma Legal?'

  id_voz_norma_legal: string = ''
  it_descripcion: string = ''
  it_descripcion_disabled: boolean = false

  dpdn_tipos_caracter: TipoCaracter[] = []
  dpdn_selected_tipo_caracter: TipoCaracter = undefined
  dpdn_tipos_caracter_disabled: boolean = false

  it_buscar: string = ''

  tbl_columnas = []
  tbl_voz_norma_legal: VozNormaLegal[] = []
  tbl_voz_norma_legal_selected: VozNormaLegal = null
  tbl_totalRecords: number = 0
  tbl_paginatorPosition: string = "bottom"
  tbl_loading: boolean = false;

  constructor(
    private normaService: NormaService,
    private messageService: MessageService,
    private crudsService: CrudsService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit() {

    this.tbl_loading = true

    this.dpdn_tipos_caracter.push( { id : "i", descripcion : 'Individual' } )
    this.dpdn_tipos_caracter.push( { id : "g", descripcion : 'General' } )
    this.dpdn_tipos_caracter.push( { id : "ig", descripcion : 'Individual-General' } )

    this.tbl_columnas = [
      { field: 'descripcion', header: 'Descripción' },
      { field: 'tipo_caracter', header: 'Tipo Carácter' }
    ]

    this.clear_components()
    this.load_data()
  }

  cancelar() {
    this.clear_components()
  }

  aceptar() {

    let error: boolean = false

    if (this.it_descripcion == '') {
      // Si el usuario no escribe en descripcion

      error = true
      this.messageService.add({severity:'warn', summary:'', detail:'Debe indicar la descripción de la Voz de Norma Legal'})
    }

    if (this.dpdn_selected_tipo_caracter == undefined) {
      // Si el usuario no escribe en descripcion

      error = true
      this.messageService.add({severity:'warn', summary:'', detail:'Debe seleccionar el Tipo de Carácter de la Voz de Norma Legal'})
    }

    if (!error) {
      // Si el usuario si escribe en descripcion

      let object: any = {}
      let caso_uso_mensaje: string

      if (this.caso_uso == 'new') {
        object['descripcion'] = this.it_descripcion
        object['tipo_caracter'] = this.dpdn_selected_tipo_caracter['id']
        caso_uso_mensaje = 'agregada'
        this.guardar(object, caso_uso_mensaje)
      } else if (this.caso_uso == 'edit') {
        object['id'] = this.id_voz_norma_legal
        object['descripcion'] = this.it_descripcion
        object['tipo_caracter'] = this.dpdn_selected_tipo_caracter['id']
        caso_uso_mensaje = 'actualizada'
        this.guardar(object, caso_uso_mensaje)

      } else if (this.caso_uso == 'delete') {

        this.confirmationService.confirm({
          header: "Atención!",
          message: this.mensaje_eliminacion,
          acceptLabel: 'Sí',
          rejectLabel: 'No',
          accept: () => {
            object['id'] = this.id_voz_norma_legal
            caso_uso_mensaje = 'eliminada'
            this.guardar(object, caso_uso_mensaje)
          }
        })
      }

    }
  }

  guardar(par_object, par_caso_uso_mensaje) {

    this.crudsService.vozNormaLegal(this.caso_uso, par_object).subscribe(data => {

      if (data['code'] != undefined) {
        // Si se guardo ok

        this.messageService.add({severity:'success', summary:'', detail: 'Voz de Norma legal ' + par_caso_uso_mensaje})
        this.clear_components()
        this.load_data(this.it_buscar)
      } else {
        // Si no se guardo ok

        if (data['error_code'] != undefined) {
          if (data['message_code'] == 'err-voz-nl-already-exist') {
            this.messageService.add({severity:'warn', summary:'', detail: 'Ya se ha registrado esta Voz de Norma Legal'})
          } else {
            this.messageService.add({severity:'warn', summary:'', detail: data['error_code'] + ' - ' + data['error_description'] + ' - ' + data['message_code'] + ' - ' + data['message_description']})
          }
        }
      }
    })
  }

  load_data(par_buscar?: string) {

    this.normaService.getVoces(null, par_buscar).subscribe(data => {

      let list = data['data']

      for (let i = 0; i < list.length; i++) {
        list[i]['tipo_caracter'] = list[i]['tipo_caracter'] == 'i' ? 'Individual' : list[i]['tipo_caracter'] == 'g' ? 'General' : 'Individual-General'
      }

      this.tbl_totalRecords = list.length
      this.tbl_voz_norma_legal = list
      this.tbl_loading = false
    })
  }

  clear_components() {

    this.caso_uso = 'new'
    this.id_voz_norma_legal = ''
    this.it_descripcion = ''
    this.it_descripcion_disabled = false
    this.dpdn_selected_tipo_caracter = undefined
    this.btn_aceptar_lbl = 'Agregar'
    this.btn_aceptar_style = 'ui-button-primary'
  }

  edit_row(par_rowData) {

    this.caso_uso = 'edit'
    this.btn_aceptar_lbl = 'Actualizar'
    this.btn_aceptar_style = 'ui-button-primary'
    this.id_voz_norma_legal = par_rowData['id']
    this.it_descripcion = par_rowData['descripcion']
    this.dpdn_selected_tipo_caracter = this.dpdn_tipos_caracter.find(x => x['id'] == (par_rowData['tipo_caracter'] == 'Individual' ? 'i' : (par_rowData['tipo_caracter'] == 'General' ? 'g' : 'ig')))

    this.it_descripcion_disabled = false
    this.dpdn_tipos_caracter_disabled = false
  }

  delete_row(par_rowData) {

    this.caso_uso = 'delete'
    this.btn_aceptar_lbl = 'Eliminar'
    this.btn_aceptar_style = 'ui-button-danger'
    this.id_voz_norma_legal = par_rowData['id']
    this.it_descripcion = par_rowData['descripcion']
    this.dpdn_selected_tipo_caracter = this.dpdn_tipos_caracter.find(x => x['id'] == (par_rowData['tipo_caracter'] == 'Individual' ? 'i' : (par_rowData['tipo_caracter'] == 'General' ? 'g' : 'ig')))

    this.it_descripcion_disabled = true
    this.dpdn_tipos_caracter_disabled = true
  }

  buscar() {

    this.load_data(this.it_buscar)
  }

  estilo_columnas(par_tipo: string, par_columna: string) {
    let estilo : string = ''

    if (par_columna == 'descripcion') {
      estilo = par_tipo == 'head' ? 'descripcion_head_style' : 'descripcion_style'
    } else if (par_columna == 'tipo_caracter') {
      estilo = par_tipo == 'head' ? 'tipo_caracter_head_style' : 'tipo_caracter_style'
    }
    return estilo
  }
}
