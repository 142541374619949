import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigsService } from './configs.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CrudsService {

  constructor(
    private http: HttpClient,
    private configsService:ConfigsService) { }

  tipoNormaLegal(par_caso_uso, par_object): Observable<any[]> {

    if (par_caso_uso == 'new') {
      return this.http.post<any[]>(this.configsService.getServerURL() + '/api/v1.0/spa/tnl/', par_object);
    } else if (par_caso_uso == 'edit') {
      return this.http.put<any[]>(this.configsService.getServerURL() + '/api/v1.0/spa/tnl/', par_object);
    } else if (par_caso_uso == 'delete') {
      return this.http.delete<any[]>(this.configsService.getServerURL() + '/api/v1.0/spa/tnl/?id=' + par_object.id);
    }
  }

  uo(par_caso_uso, par_object): Observable<any[]> {

    if (par_caso_uso == 'new') {
      return this.http.post<any[]>(this.configsService.getServerURL() + '/api/v1.0/spa/uo/', par_object);
    } else if (par_caso_uso == 'edit') {
      return this.http.put<any[]>(this.configsService.getServerURL() + '/api/v1.0/spa/uo/', par_object);
    } else if (par_caso_uso == 'delete') {
      return this.http.delete<any[]>(this.configsService.getServerURL() + '/api/v1.0/spa/uo/?id=' + par_object.id);
    }
  }

  vozNormaLegal(par_caso_uso, par_object): Observable<any[]> {

    if (par_caso_uso == 'new') {
      return this.http.post<any[]>(this.configsService.getServerURL() + '/api/v1.0/spa/vnl/', par_object);
    } else if (par_caso_uso == 'edit') {
      return this.http.put<any[]>(this.configsService.getServerURL() + '/api/v1.0/spa/vnl/', par_object);
    } else if (par_caso_uso == 'delete') {
      return this.http.delete<any[]>(this.configsService.getServerURL() + '/api/v1.0/spa/vnl/?id=' + par_object.id);
    }
  }

  afectacionNormaLegal(par_caso_uso, par_object): Observable<any[]> {

    if (par_caso_uso == 'new') {
      return this.http.post<any[]>(this.configsService.getServerURL() + '/api/v1.0/spa/anl/', par_object);
    } else if (par_caso_uso == 'edit') {
      return this.http.put<any[]>(this.configsService.getServerURL() + '/api/v1.0/spa/anl/', par_object);
    } else if (par_caso_uso == 'delete') {
      return this.http.delete<any[]>(this.configsService.getServerURL() + '/api/v1.0/spa/anl/?id=' + par_object.id);
    }
  }

  oficina(par_caso_uso, par_object): Observable<any[]> {

    if (par_caso_uso == 'new') {
      return this.http.post<any[]>(this.configsService.getServerURL() + '/api/v1.0/spa/oficina/', par_object);
    } else if (par_caso_uso == 'edit') {
      return this.http.put<any[]>(this.configsService.getServerURL() + '/api/v1.0/spa/oficina/', par_object);
    } else if (par_caso_uso == 'delete') {
      return this.http.delete<any[]>(this.configsService.getServerURL() + '/api/v1.0/spa/oficina/?id=' + par_object.id);
    }
  }

  oficinaTipoNormaLegal(par_oficina, par_oficina_tipo_nl): Observable<any[]> {

    let object_row = {};
    let list = [];
    for (let i = 0; i < par_oficina_tipo_nl.length; i++) {
      object_row = {};
      object_row['id_tipo_nl'] = par_oficina_tipo_nl[i]['id'];
      list.push(object_row);
    }

    let object = {};
    object['id_oficina'] = par_oficina.id;
    object['tipos_nl'] = list;

    return this.http.post<any[]>(this.configsService.getServerURL() + '/api/v1.0/spa/oficina_tnl/', object);
  }

  OficinaNotif(par_caso_uso, par_object): Observable<any[]> {

    if (par_caso_uso == 'new') {
      return this.http.post<any[]>(this.configsService.getServerURL() + '/api/v1.0/spa/oficina_notif/', par_object);
    } else if (par_caso_uso == 'edit') {
      return this.http.put<any[]>(this.configsService.getServerURL() + '/api/v1.0/spa/oficina_notif/', par_object);
    } else if (par_caso_uso == 'delete') {
      return this.http.delete<any[]>(this.configsService.getServerURL() + '/api/v1.0/spa/oficina_notif/?id=' + par_object.id);
    }
  }
}
