import { Component, OnInit } from '@angular/core';
import { Uo } from '../../models/uo'
import { RolAuth } from '../../models/rol-auth'
import { MessageService } from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import { NormaService } from '../../services/norma.service'
import { CrudsService } from '../../services/cruds.service'
import { AuthService } from '../../services/auth.service'

@Component({
  selector: 'app-oficina',
  templateUrl: './oficina.component.html',
  styleUrls: ['./oficina.component.scss']
})
export class OficinaComponent implements OnInit {

  caso_uso: string = 'new'
  btn_aceptar_lbl: string = ''
  btn_aceptar_style: string = ''
  mensaje_eliminacion: string = '¿Confirma la elminación de esta Oficina?'

  id_oficina: string = ''

  it_descripcion: string = ''
  it_descripcion_disabled: boolean = false
  it_descripcion_corta: string = ''
  it_descripcion_corta_disabled: boolean = false

  dpdn_unidades_organizativas: Uo[] = []
  dpdn_selected_uo: Uo = undefined
  dpdn_unidades_organizativas_disabled: boolean = false

  dpdn_rol_auths: RolAuth[] = []
  dpdn_selected_rol_auth: RolAuth = undefined
  dpdn_rol_auths_disabled: boolean = false

  it_buscar: string = ''

  tbl_columnas = []
  tbl_oficina: Uo[] = []
  tbl_oficina_selected: Uo = null
  tbl_paginatorPosition: String = 'bottom'
  tbl_totalRecords: number = 0
  tbl_loading: boolean = false;

  constructor(
    private normaService: NormaService,
    private messageService: MessageService,
    private crudsService: CrudsService,
    private confirmationService: ConfirmationService,
    private authService: AuthService
  ) { }

  ngOnInit() {

    this.normaService.getUO().subscribe(data=> {
      let obj=data['data']
      this.dpdn_unidades_organizativas=[...obj]
    })

    let authorizations = this.authService.getUserDataArea()

    let list = []
    if (authorizations.length > 0) {
      for (let i = 0; i < authorizations.length; i++) {
        let object = {}
        object['id'] = i
        object['descripcion'] = authorizations[i]
        list.push(object)
      }
    }
    this.dpdn_rol_auths = list

    this.tbl_loading = true

    this.tbl_columnas = [
      { field: 'descripcion', header: 'Descripción' },
      { field: 'descripcion_corta', header: 'Abreviatura' },
      { field: 'descripcion_uo', header: 'Unidad Organizativa' },
      { field: 'rol_auth', header: 'Authorization' }
    ]

    this.clear_components()
    this.load_data()
  }

  cancelar() {
    this.clear_components()
  }

  aceptar() {

    let datos_ok: boolean = true

    if (this.it_descripcion == '') {
      // Si el usuario no escribe en descripcion

      this.messageService.add({severity:'warn', summary:'', detail:'Debe indicar la descripción de la Oficina'})
      datos_ok = false
    }

    if (this.it_descripcion_corta == '') {
      // Si el usuario no escribe en descripcion

      this.messageService.add({severity:'warn', summary:'', detail:'Debe indicar la abreviatura de la Oficina'})
      datos_ok = false
    }

    if (this.dpdn_selected_uo == undefined) {
      // si el usuario no selecciono

      this.messageService.add({severity:'warn', summary:'', detail:'Indique la Unidad Organizativa'});
      datos_ok = false
    }

    if (this.dpdn_selected_rol_auth == undefined) {
      // si el usuario no selecciono

      this.messageService.add({severity:'warn', summary:'', detail:'Indique Authorization'});
      datos_ok = false
    }

    if (datos_ok) {
      // Si datos ok

      let object: any = {}
      let caso_uso_mensaje: string

      if (this.caso_uso == 'new') {
        object['descripcion'] = this.it_descripcion
        object['descripcion_corta'] = this.it_descripcion_corta
        object['id_uo'] = this.dpdn_selected_uo.id
        object['rol_auth'] = this.dpdn_selected_rol_auth.descripcion
        caso_uso_mensaje = 'agregada'
        this.guardar(object, caso_uso_mensaje)
      } else if (this.caso_uso == 'edit') {
        object['id'] = this.id_oficina
        object['descripcion'] = this.it_descripcion
        object['descripcion_corta'] = this.it_descripcion_corta
        object['id_uo'] = this.dpdn_selected_uo.id
        object['rol_auth'] = this.dpdn_selected_rol_auth.descripcion
        caso_uso_mensaje = 'actualizada'
        this.guardar(object, caso_uso_mensaje)
      } else if (this.caso_uso == 'delete') {
        this.confirmationService.confirm({
          header: "Atención!",
          message: this.mensaje_eliminacion,
          acceptLabel: 'Sí',
          rejectLabel: 'No',
          accept: () => {
            object['id'] = this.id_oficina
            caso_uso_mensaje = 'eliminada'
            this.guardar(object, caso_uso_mensaje)
          }
        })
      }
    }
  }

  guardar(par_object, par_caso_uso_mensaje) {

    this.crudsService.oficina(this.caso_uso, par_object).subscribe(data => {

      if (data['code'] != undefined) {
        // Si se guardo ok

        this.messageService.add({severity:'success', summary:'', detail: 'Oficina ' + par_caso_uso_mensaje})
        this.clear_components()
        this.load_data(this.it_buscar)
      } else {
        // Si no se guardo ok

        if (data['error_code'] != undefined) {
          if (data['message_code'] == 'err-oficina-already-exist') {
            this.messageService.add({severity:'warn', summary:'', detail: 'Ya se ha registrado una Oficina con igual descripción, abreviatura o authorization'})
          } else {
            this.messageService.add({severity:'warn', summary:'', detail: data['error_code'] + ' - ' + data['error_description'] + ' - ' + data['message_code'] + ' - ' + data['message_description']})
          }
        }
      }
    })
  }

  load_data(par_buscar?: string) {

    this.normaService.getOficinas(null, par_buscar).subscribe(data => {

      let list = data['data']

      this.tbl_totalRecords = list.length
      this.tbl_oficina = list
      this.tbl_loading = false
    })
  }

  clear_components() {

    this.caso_uso = 'new'
    this.id_oficina = ''
    this.it_descripcion = ''
    this.it_descripcion_disabled = false
    this.it_descripcion_corta = ''
    this.it_descripcion_corta_disabled = false
    this.dpdn_selected_uo = undefined
    this.dpdn_unidades_organizativas_disabled = false
    this.dpdn_selected_rol_auth = undefined
    this.dpdn_rol_auths_disabled = false
    this.btn_aceptar_lbl = 'Agregar'
    this.btn_aceptar_style = 'ui-button-primary'
  }

  edit_row(par_rowData) {

    this.caso_uso = 'edit'
    this.btn_aceptar_lbl = 'Actualizar'
    this.btn_aceptar_style = 'ui-button-primary'
    this.id_oficina = par_rowData.id
    this.it_descripcion = par_rowData.descripcion
    this.it_descripcion_disabled = false
    this.it_descripcion_corta = par_rowData.descripcion_corta
    this.it_descripcion_corta_disabled = false
    this.dpdn_selected_uo = this.dpdn_unidades_organizativas.find(x => x.descripcion == par_rowData.descripcion_uo)
    this.dpdn_selected_rol_auth = this.dpdn_rol_auths.find(x => x.descripcion == par_rowData.rol_auth)
  }

  delete_row(par_rowData) {

    this.caso_uso = 'delete'
    this.btn_aceptar_lbl = 'Eliminar'
    this.btn_aceptar_style = 'ui-button-danger'
    this.id_oficina = par_rowData.id
    this.it_descripcion = par_rowData.descripcion
    this.it_descripcion_disabled = true
    this.it_descripcion_corta = par_rowData.descripcion_corta
    this.it_descripcion_corta_disabled = true
    this.dpdn_selected_uo = this.dpdn_unidades_organizativas.find(x => x.descripcion == par_rowData.descripcion_uo)
    this.dpdn_unidades_organizativas_disabled = true
    this.dpdn_selected_rol_auth = this.dpdn_rol_auths.find(x => x.descripcion == par_rowData.rol_auth)
    this.dpdn_rol_auths_disabled = true
  }

  buscar() {

    this.load_data(this.it_buscar)
  }

  estilo_columnas(par_tipo: string, par_columna: string) {
    let estilo : string = ''

    if (par_columna == 'descripcion') {
      estilo = par_tipo == 'head' ? 'descripcion_head_style' : 'descripcion_style'
    } else if (par_columna == 'descripcion_corta') {
      estilo = par_tipo == 'head' ? 'descripcion_corta_head_style' : 'descripcion_corta_style'
    } else if (par_columna == 'uo_descripcion') {
      estilo = par_tipo == 'head' ? 'uo_descripcion_head_style' : 'uo_descripcion_style'
    } else if (par_columna == 'rol_auth') {
      estilo = par_tipo == 'head' ? 'rol_auth_head_style' : 'rol_auth_style'
    }
    return estilo
  }
}
