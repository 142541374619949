import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {AuthService} from '../services/auth.service';
// import { map } from 'rxjs/operators';
import {ConfigsService} from './configs.service';

const headers_ = new HttpHeaders()
    .set('Content-Type', 'application/json');
    const headers_post = new HttpHeaders()
        .set('Content-Type', 'multipart/form-data');

@Injectable({
  providedIn: 'root'
})
export class DocumentoService {

  constructor(
    private configsService: ConfigsService, 
    private httpClient: HttpClient, 
    private authService: AuthService) { }

  postDoc(doc, meta) {

    // console.log("file before sending is")
    // console.log(doc)

    const areas = this.authService.getUserDataArea();
    const formData = new FormData();
  
    formData.append('numero', meta.numero);
    formData.append('descripcion_oficina', areas[0]);
    formData.append('file', doc);

     return this.httpClient.post(this.configsService.getServerURL() + '/api/v1.0/spa/nl/', formData);
  }

  format_date_for_insert(par_date: string) {
   const date_arr = par_date.split('/');
   // aaaa/mm/dd
   return (date_arr[2] + '/' + date_arr[1]  + '/' + date_arr[0]);
  }

  getNormasLegales(criterios_busqueda, par_rol?: string): Observable<any[]> {

    let other_filters = '';

    other_filters += '&rol_auth=' + this.authService.getUserDataArea()[0];

    if (criterios_busqueda.numero !== '') {
      other_filters += '&numero=' + criterios_busqueda.numero;
    }

    if (criterios_busqueda.idVoz !== '') {
      other_filters += '&id_voz_nl=' + criterios_busqueda.idVoz;
    }

    if (criterios_busqueda.caracter !== '') {
      other_filters += '&caracter=' + criterios_busqueda.caracter.toLowerCase();
    }

    if (criterios_busqueda.idTipo !== '') {
      other_filters += '&id_tipo_nl=' + criterios_busqueda.idTipo;
    }

    if (criterios_busqueda.resena !== '') {
      other_filters += '&resena=' + criterios_busqueda.resena;
    }

    // if (criterios_busqueda.ocr_text !== '') {
    //   other_filters += '&ocr_text=' + criterios_busqueda.ocr_text;
    // }

    if (criterios_busqueda.fechaDesde !== '') { 
      other_filters += '&ffirma_desde=' + this.format_date_for_insert(criterios_busqueda.fechaDesde);
    }

    if (criterios_busqueda.fechaHasta !== '') { 
      other_filters += '&ffirma_hasta=' + this.format_date_for_insert(criterios_busqueda.fechaHasta);
    }

    if (criterios_busqueda.rows !== '') {
      other_filters += '&limit=' + criterios_busqueda.rows;
    }

    if (criterios_busqueda.first !== '') {
      other_filters += '&skip=' + criterios_busqueda.first;
    }

    return this.httpClient.get<any[]>(this.configsService.getServerURL() + '/api/v1.0/spa/pub/nl/?' + other_filters, {headers: headers_});
  }

  // getPagedNormasLegales(dt, par_rol?: string) {

  //   let other_filters = '';
  //   let first = 0;
  //   let rows = 10;

  //   other_filters += '&rol_auth=' + this.authService.getUserDataArea()[0];

  //   try {
  //     if (dt.filters.numero.value.length > 0) {
  //       // if input is empty.. aka blank, then wont enter here
  //       other_filters += '&numero=' + dt.filters.numero.value;
  //     }
  //   } catch (err) {
  //     // console.log("no numero in filter")
  //   }

  //   try {
  //     if (dt.filters.idVoz.value.length > 0) {
  //       // if input is empty.. aka blank, then wont enter here
  //       other_filters += '&id_voz_nl=' + dt.filters.idVoz.value;
  //     }
  //   } catch (err) {
  //     // console.log("no voz in filter")
  //   }

  //   try {
  //     if (dt.filters.caracter.value.length > 0) { // if input is empty.. aka blank, then wont enter here
  //       other_filters += '&caracter=' + dt.filters.caracter.value.toLowerCase();
  //     }
  //   } catch (err) {
  //     // console.log("no caracterin filter")
  //   }

  //   try {
  //     if (dt.filters.idTipo.value.length > 0) { // if input is empty.. aka blank, then wont enter here
  //       other_filters += '&id_tipo_nl=' + dt.filters.idTipo.value;
  //     }
  //   } catch (err) {
  //     // console.log("no tipo in filter")
  //   }

  //   try {
  //     if (dt.filters.resena.value.length > 0) { // if input is empty.. aka blank, then wont enter here
  //       other_filters += '&resena=' + dt.filters.resena.value;
  //     }
  //   } catch (err) {
  //     // console.log("no numero in filter")
  //   }

  //   try {
  //     if (dt.filters.ocr_text.value.length > 0) { // if input is empty.. aka blank, then wont enter here
  //       other_filters += '&ocr_text=' + dt.filters.ocr_text.value;
  //     }
  //   } catch (err) {
  //     // console.log("no ocr in filter")
  //   }

  //   try {
  //     // console.log("FECHA DESDE VALUE IS....")
  //     // console.log(dt.filters.fechaHasta.value)
  //     // console.log(this.format_date_for_insert(dt.filters.fechaHasta.value))
  //     if (dt.filters.fechaHasta.value.length > 0) { 
  //       // if input is empty.. aka blank, then wont enter here
  //       other_filters += '&ffirma_hasta=' + this.format_date_for_insert(dt.filters.fechaHasta.value);
  //     }
  //   } catch (err) {
  //     // console.log("no fecha hasta in filter")
  //   }

  //   try {
  //     // console.log("FECHA DESDE VALUE IS....")
  //     // console.log(dt.filters.fechaDesde.value)
  //     // console.log(this.format_date_for_insert(dt.filters.fechaDesde.value))
  //     if (dt.filters.fechaDesde.value.length > 0) { 
  //       // if input is empty.. aka blank, then wont enter here
  //       other_filters += '&ffirma_desde=' + this.format_date_for_insert(dt.filters.fechaDesde.value);
  //     }
  //   } catch (err) {
  //     // console.log("no fecha desde in filter")
  //   }

  //   try {
  //     if (dt.first >= 0) {
  //       first = dt.first;
  //     }
  //   } catch (err) {
  //     // console.log("no first")
  //   }

  //   try {
  //     if (dt.rows >= 0) {
  //       rows = dt.rows;
  //     }
  //   } catch (err) {
  //     // console.log("no rows")
  //   }

  //   if (par_rol === 'PUBLIC') {

  //     return this.httpClient.get(this.configsService.getServerURL() + '/api/v1.0/spa/pub/nl/?skip=' + first + '&limit=' + rows + other_filters, {headers: headers_});
  //   } else {

  //     return this.httpClient.get(this.configsService.getServerURL() + '/api/v1.0/spa/nl/?skip=' + first + '&limit=' + rows + other_filters, {headers: headers_});
  //   }
  // }

  getNormaLegal(par_id_norma_legal, par_rol?: string) {

    if (par_rol === 'PUBLIC') {

      return this.httpClient.get(this.configsService.getServerURL() + '/api/v1.0/spa/pub/nl/?id=' + par_id_norma_legal, {headers: headers_});
    } else {

      return this.httpClient.get(this.configsService.getServerURL() + '/api/v1.0/spa/nl/?id=' + par_id_norma_legal, {headers: headers_});
    }
  }
}
