import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigsService } from './configs.service';
import { map } from 'rxjs/operators';
// import { map, retry, catchError } from 'rxjs/operators';
// import { Observable, of as observableOf } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private configsService: ConfigsService,
    private http: HttpClient) { }

  body = {};
  server = '';
  isFirstLog: boolean;

  httpOptions = {
  headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'my-auth-token'
    })
  };
  helper = new JwtHelperService();

  getAccessJWT() {

    let accessToken = localStorage.getItem('access_token');

    if (accessToken === null) {
      accessToken = 'no_token';
    }
    return accessToken;
  }

  getUserRol() {

    let rol = '';

    if (this.getAccessJWT() !== 'no_token') {
      // si hay token, hay un usuario login

      if (this.getUserData()['resource_access']['spa'] !== undefined) {

        // obtengo los atributos del usuario login provenientes de keycloack
        const roles = this.getUserData()['resource_access']['spa']['roles'];

        for (let i = 0; i < roles.length; i++) {
          
          if (roles[i].substring(0, 4) === 'ROL-') {
            // si el atributo es un rol

            rol = roles[i].substring(4, roles[i].length);
          }
        }
      } else {

      rol = 'PUBLIC';
      }
    } else {
      // si no hay token, es usuario publico, no autenticado
      
      rol = 'PUBLIC';
    }
    return rol;
  }

  getUserDataArea() {

    let areas = [] = [];

    if (this.getUserRol() === 'PUBLIC') {
      areas = [''];
    } else {
      let roles = [];
      roles = this.getUserData()['resource_access']['spa']['roles'];
      for (let i = 0; i < roles.length; i++) {
        if (roles[i].substring(0, 4) === 'AREA') {
          areas.push(roles[i].substring(5, roles[i].length));
        }
      }
    }
    return areas;
  }

  getTokenWithTempCode(par_code) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/x-www-form-urlencoded'
      })
    };

    const body = new HttpParams().set('code', par_code).append('client_id', this.configsService.getKeyCloakClientID()).append('grant_type', 'authorization_code').append('redirect_uri', this.configsService.getServerFrontURL());
    const server = this.configsService.getKeyCloakAddress() + '/auth/realms/' + this.configsService.getKeyCloakRealm() + '/protocol/openid-connect/token';

    // console.log("this.server is" + server)
    // console.log("STEP 1")
    // OPTION A ==> c.code exists, that means this app has just loaded from a fresh login.
    if (par_code !== undefined) {
    
      try {
        return this.http.post<any>(server, body, httpOptions).pipe(map(
          res => this.saveAccessJWT(res)
        ));
      } catch (err) {
        console.log('auth server error');
        console.log(err);
      }
    }
    
    // OPTION B ==> c.code does not exists its probably because user navigated away from page (refreshed somehow)
    if (par_code === undefined) { 
      alert('CODE?');
    }
  }

  saveAccessJWT(tk) {

    try {

      localStorage.setItem('access_token', tk.access_token);
      return true;
    } catch (err) {

      console.log(err);
      console.log('something happened while decoding jwt');
      return false;
    }
  }

  getUserData() {

    const access_token = this.getAccessJWT();
  
    if (access_token !== 'no_token') {

      return this.helper.decodeToken(access_token);
    } else {

      console.log('ERR getting user data');
    }
  }

  getUserDataUserName() {
    return this.getUserData()['preferred_username'];
  }

  checkTokenExpiry() {

    // console.log("time in this baddie is")
    // console.log(new Date())
    // console.log("and exp field in JWT")
    // console.log(this.JWT_ACCESS_DECODED['exp'])
    // const date = new Date(this.JWT_ACCESS_DECODED['exp'] * 1000);
    // console.log(date.toString())
    // console.log("CHECKING IF TOKEN EXPIRED IN AUTH SERVICE-----")
    // console.log("access_token are you there?")
    // console.log(localStorage.getItem("access_token"))

    return this.helper.isTokenExpired(localStorage.getItem('access_token'));
  }

  logout() {

    localStorage.removeItem('access_token');
   
    // The replace() method replaces the current document with a new one.
    // The difference between this method and assign(), is that replace() removes the URL of the current document from the document history, meaning that it is not possible to use the "back" button to navigate back to the original document.    
    window.location.replace(this.configsService.getKeyCloakAddress() + '/auth/realms/' + this.configsService.getKeyCloakRealm() + '/protocol/openid-connect/logout?redirect_uri=' + this.configsService.getServerFrontURLEncoded());
  }

  setFirstLog(huh) {
    this.isFirstLog = huh;
  }

  getFirstLog() {
    return this.isFirstLog;
  }
}
