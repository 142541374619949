import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from '../services/auth.service'

/** Pass untouched request through to the next request handler. */
@Injectable()
export class NoopInterceptor implements HttpInterceptor {

 constructor(
   private authService: AuthService, 
   private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler):

  Observable<HttpEvent<any>> {

    if (this.authService.checkTokenExpiry() && this.authService.getAccessJWT() !== 'no_token' && !this.authService.getFirstLog()) {
      // if there is no token in localstorage it WILL PASS anyways (first request might have not have it yet.) 

      console.log('Service has detected an expired JWT, redirecting...');

      localStorage.removeItem('access_token');
      this.router.navigate(['/logout']);
      // if it expired, then it has to KICK USER TO LOGIN PAGE ****PENDING TO USE REFRESH TOKEN TO UPDATE EXPIRED ACCESS TOKEN IN ANOTHER VERSION****
    }
    const authToken = this.authService.getAccessJWT();
    const authReq = req.clone( { headers: req.headers.set('Authorization', 'Bearer ' + authToken ) });
    return next.handle(authReq);
  }
}
