import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TipoNormaLegalComponent } from '../../components/tipo-norma-legal/tipo-norma-legal.component'
import { VozNormaLegalComponent } from '../../components/voz-norma-legal/voz-norma-legal.component'
import { UoComponent } from '../../components/uo/uo.component'
import { AfectacionNormaLegalComponent } from '../../components/afectacion-norma-legal/afectacion-norma-legal.component'
import { OficinaComponent } from '../../components/oficina/oficina.component'
import { OficinaTipoNormaLegalComponent } from '../../components/oficina-tipo-norma-legal/oficina-tipo-norma-legal.component'
import { OficinaNotifComponent } from '../../components/oficina-notif/oficina-notif.component'

import {CardModule} from 'primeng/card';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {DropdownModule} from 'primeng/dropdown';
import {CheckboxModule} from 'primeng/checkbox';

@NgModule({
  declarations: [
    TipoNormaLegalComponent,
    VozNormaLegalComponent,
    UoComponent,
    AfectacionNormaLegalComponent,
    OficinaComponent,
    OficinaTipoNormaLegalComponent,
    OficinaNotifComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    CardModule,
    InputTextModule,
    ButtonModule,
    TableModule,
    ToastModule,
    ConfirmDialogModule,
    ToggleButtonModule,
    DropdownModule,
    CheckboxModule
  ]
})
export class PrimaryCrudsModule { }
