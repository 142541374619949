import { Component, OnInit } from '@angular/core';
import { TipoNormaLegal } from '../../models/tipo-norma-legal';
import { MessageService } from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import { NormaService } from '../../services/norma.service';
import { CrudsService } from '../../services/cruds.service';

@Component({
  selector: 'app-tipo-norma-legal',
  templateUrl: './tipo-norma-legal.component.html',
  styleUrls: ['./tipo-norma-legal.component.scss']
})
export class TipoNormaLegalComponent implements OnInit {

  caso_uso = 'new';
  btn_aceptar_lbl = '';
  btn_aceptar_style = '';
  mensaje_eliminacion = '¿Confirma la elminación de este Tipo de Norma Legal?';

  id_tipo_norma_legal = '';
  it_descripcion = '';
  it_descripcion_disabled = false;
  chbx_numero_requerido = true;
  chbx_numero_requerido_disabled = false;
  it_buscar = '';

  tbl_columnas = [];
  tbl_tipo_norma_legal: TipoNormaLegal[] = [];
  tbl_tipo_norma_legal_selected: TipoNormaLegal = null;
  tbl_rows = 5;
  tbl_totalRecords = 0;
  tbl_paginatorPosition = 'bottom';
  tbl_loading = false;

  constructor(
    private normaService: NormaService,
    private messageService: MessageService,
    private crudsService: CrudsService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit() {

    this.tbl_loading = true;

    this.tbl_columnas = [
      { field: 'descripcion', header: 'Descripción' },
      { field: 'numero_requerido', header: 'Nro Requerido' },
    ];

    this.clear_components();
    this.load_data();
  }

  cancelar() {
    this.clear_components();
  }

  aceptar() {

    if (this.it_descripcion === '') {
      // Si el usuario no escribe en descripcion

      this.messageService.add({severity: 'warn', summary: '', detail: 'Debe indicar la descripción del Tipo de Norma Legal'});
    } else {
      // Si el usuario si escribe en descripcion

      let object: any = {};
      let caso_uso_mensaje: string;

      if (this.caso_uso === 'new') {
        object['descripcion'] = this.it_descripcion;
        object['numero_requerido'] = this.chbx_numero_requerido;
        caso_uso_mensaje = 'agregada';
        this.guardar(object, caso_uso_mensaje);
      } else if (this.caso_uso == 'edit') {
        object['id'] = this.id_tipo_norma_legal;
        object['descripcion'] = this.it_descripcion;
        object['numero_requerido'] = this.chbx_numero_requerido;
        caso_uso_mensaje = 'actualizada';
        this.guardar(object, caso_uso_mensaje);
      } else if (this.caso_uso === 'delete') {

        this.confirmationService.confirm({
          header: 'Atención!',
          message: this.mensaje_eliminacion,
          acceptLabel: 'Sí',
          rejectLabel: 'No',
          accept: () => {
            object['id'] = this.id_tipo_norma_legal;
            caso_uso_mensaje = 'eliminada';
            this.guardar(object, caso_uso_mensaje);
          }
        });
      }

    }
  }

  guardar(par_object, par_caso_uso_mensaje) {

    this.crudsService.tipoNormaLegal(this.caso_uso, par_object).subscribe(data => {

      if (data['code'] !== undefined) {
        // Si se guardo ok

        this.messageService.add({severity: 'success', summary: '', detail: 'Tipo de Norma legal ' + par_caso_uso_mensaje});
        this.clear_components();
        this.load_data(this.it_buscar);
      } else {
        // Si no se guardo ok

        if (data['error_code'] !== undefined) {
          if (data['message_code'] === 'err-tipo-nl-already-exist') {
            this.messageService.add({severity: 'warn', summary: '', detail: 'Ya se ha registrado este Tipo de Norma Legal'});
          } else {
            this.messageService.add({severity: 'warn', summary: '', detail: data['error_code'] + ' - ' + data['error_description'] + ' - ' + data['message_code'] + ' - ' + data['message_description']});
          }
        }
      }
    });
  }

  load_data(par_buscar?: string) {

    this.normaService.getTiposNormas(null, par_buscar).subscribe(data => {

      let list = data['data'];

      for (let i = 0; i < list.length; i++) {

        if (list[i]['numero_requerido']) {
          list[i]['numero_requerido'] = '';
        } else {
          list[i]['numero_requerido'] = 'No';
        }
      }
        
      this.tbl_totalRecords = list.length;
      this.tbl_tipo_norma_legal = list;
      this.tbl_loading = false;
    });
  }

  clear_components() {

    this.caso_uso = 'new';
    this.id_tipo_norma_legal = '';
    this.it_descripcion = '';
    this.it_descripcion_disabled = false;
    this.chbx_numero_requerido = true;
    this.chbx_numero_requerido_disabled = false;
    this.btn_aceptar_lbl = 'Agregar';
    this.btn_aceptar_style = 'ui-button-primary';
  }

  edit_row(par_rowData) {

    this.caso_uso = 'edit';
    this.btn_aceptar_lbl = 'Actualizar';
    this.btn_aceptar_style = 'ui-button-primary';
    this.id_tipo_norma_legal = par_rowData.id;
    this.it_descripcion = par_rowData.descripcion;
    this.it_descripcion_disabled = false;
    this.chbx_numero_requerido = par_rowData.numero_requerido === '' ? true : false;
    this.chbx_numero_requerido_disabled = false;
  }

  delete_row(par_rowData) {

    this.caso_uso = 'delete';
    this.btn_aceptar_lbl = 'Eliminar';
    this.btn_aceptar_style = 'ui-button-danger';
    this.id_tipo_norma_legal = par_rowData.id;
    this.it_descripcion = par_rowData.descripcion;
    this.it_descripcion_disabled = true;
    this.chbx_numero_requerido = par_rowData.numero_requerido;
    this.chbx_numero_requerido_disabled = true;
  }

  buscar() {

    this.load_data(this.it_buscar);
  }

  estilo_columnas(par_tipo: string, par_columna: string) {
    let estilo = '';

    if (par_columna === 'descripcion') {
      estilo = par_tipo === 'head' ? 'descripcion_head_style' : 'descripcion_style';
    } else if (par_columna === 'numero_requerido') {
      estilo = par_tipo === 'head' ? 'numero_requerido_head_style' : 'numero_requerido_style';
    }
    return estilo;
  }
}
