import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {NormaService} from '../../services/norma.service';
import {UtilsService} from '../../services/utils.service';

interface NormaAf {
  'id_norma_legal'?: string;
  'id_tipo_nl'?: string;
  'numero'?: string;
  'id_afectacion_nl'?: string;
  'detalle_afectacion'?: string;
  'descripcion_tipo_nl'?: string;
  'descripcion_afectacion_nl'?: string;
}

interface Destino {
  'id': string;
  'descripcion': string;
  'email': string;
}

@Component({

  selector: 'app-confirma-norma',
  templateUrl: './confirma-norma.component.html',
  styleUrls: ['./confirma-norma.component.scss']
})
export class ConfirmaNormaComponent implements OnInit {

  @Input('nl')
  input_nl: any;

  @Output()
  on_confirma_nl = new EventEmitter<boolean>();

  tipo_nl_descripcion = '';
  numero = '';
  fecha_firma = '';
  caracter = '';
  voz_nl_descripcion = '';
  resena = '';
  normasAfectadas: NormaAf[] = [];
  destinosOficinaNotif: Destino[] = [];
  file_name = '';
  file_size = '';

  show_oficinas_a_notificar = false;

  constructor(
    private normaService: NormaService,
    private utilsServices: UtilsService) { }

  ngOnInit() {

  }

  ngOnChanges() {

    if (Object.keys(this.input_nl).length !== 0) {

      this.normaService.getTiposNormas(this.input_nl.id_tipo_nl).subscribe(data => {
        this.tipo_nl_descripcion = data['data'][0]['descripcion'];
      });

      this.normaService.getVoces(this.input_nl.id_voz_nl).subscribe(data => {
        this.voz_nl_descripcion = data['data'][0]['descripcion'];
      });

      this.numero = this.input_nl.numero;
      this.fecha_firma = this.utilsServices.format_date(this.input_nl.fecha_firma);
      this.caracter = this.input_nl.caracter;
      this.resena = this.input_nl.resena;
      this.normasAfectadas = this.input_nl.nl_afectadas;
      this.destinosOficinaNotif = this.input_nl.oficinas_a_notificar;
      this.file_name = this.input_nl.file.name;
      this.file_size = this.input_nl.file.size;
    }
  }

  publica_nl(par_publica_nl: boolean) {

    this.on_confirma_nl.emit(par_publica_nl);
  }
}
