import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import {NormaService} from '../../services/norma.service';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-doc-viewer',
  templateUrl: './doc-viewer.component.html',
  styleUrls: ['./doc-viewer.component.scss']
})
export class DocViewerComponent implements OnInit {

  @ViewChild('pdfViewer') 
  pdfViewer;

  f_display_pdf: boolean;
  id_pdf = '';

  showProgressSpinner = false;

  constructor(
    private normaService: NormaService, 
    @Inject(MAT_DIALOG_DATA) data) {

    this.id_pdf = data.id_pdf;
  }

  ngOnInit() {

    this.showProgressSpinner = true;
    this.display_pdf(this.id_pdf);
  }

  display_pdf(par_id_pdf) {

    this.normaService.getPDF(par_id_pdf).subscribe(data => {
      this.manageDocResponse(data);
    });
    this.f_display_pdf = true;
  }

  manageDocResponse(data) {

    const doc_ = new Blob([data], { type: 'application/pdf' });
    this.pdfViewer.pdfSrc = doc_; // pdfSrc can be Blob or Uint8Array
    this.pdfViewer.refresh();

    this.showProgressSpinner = false;
    // this.display_pdf()
    console.log('got DOC? inside doc viewer');
    // console.table(data)
    // this.juicios=data.response
  }
}
