import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor() { }

  getErrorMessage(par_error_code) {

    let _error_message: string = ''

    switch (par_error_code) {
      case 'err-norma-legal-pdf-required': {
        _error_message = 'Debe adjuntar el pdf de la Norma Legal'
      }
    }

    return _error_message
  }
}
