import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ConfigsService} from './configs.service';
import { Observable, Subject } from 'rxjs';

import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})

export class NormaService {

  id_norma_legal = '';
  id_tipo_nl = '';
  numero = '';

  // search_tipo_nl: string = ''
  // search_numero: string = ''
  // search_id_caracter: string = ''
  // search_resena: string = ''
  // search_id_voz_nl: string = ''
  // search_fecha_firma_desde: string = ''
  // search_fecha_firma_hasta: string = ''
  // search_texto: string = ''

  constructor(
    private http: HttpClient,
    private configsService: ConfigsService,
    private authService: AuthService) { }

  // setSearchCriteria(par_tipo_nl: any, par_id_caracter: string, par_numero: string, par_resena: string, par_id_voz_nl: string, par_fecha_firma_desde: string, par_fecha_firma_hasta: string, par_texto: string) {
  //
  //   this.search_tipo_nl = par_tipo_nl
  //   this.search_numero = par_numero
  //   this.search_id_caracter = par_id_caracter
  //   this.search_resena = par_resena
  //   this.search_id_voz_nl = par_id_voz_nl
  //   this.search_fecha_firma_desde = par_fecha_firma_desde
  //   this.search_fecha_firma_hasta = par_fecha_firma_hasta
  //   this.search_texto = par_texto
  // }
  //
  // getSearchCriteria() {
  //
  //   let object = {}
  //   object['tipo_nl'] = this.search_tipo_nl
  //   object['numero'] = this.search_numero
  //   object['id_caracter'] = this.search_id_caracter
  //   object['resena'] = this.search_resena
  //   object['id_voz_nl'] = this.search_id_voz_nl
  //   object['fecha_firma_desde'] = this.search_fecha_firma_desde
  //   object['fecha_firma_hasta'] = this.search_fecha_firma_hasta
  //   object['texto'] = this.search_texto
  //
  //   return object
  // }

  setNormaLegalToUpdate(par_id_norma_legal: string, par_id_tipo_nl: string, par_numero: string) {

    this.id_norma_legal = par_id_norma_legal;
    this.id_tipo_nl = par_id_tipo_nl;
    this.numero = par_numero;
  }

  getNormaLegalToUpdate() {

    const object = {};
    object['id_norma_legal'] = this.id_norma_legal;
    object['id_tipo_nl'] = this.id_tipo_nl;
    object['numero'] = this.numero;
    return object;
  }

  getTiposNormas(par_id_tipo_nl?: string, par_buscar?: string) {

    // obtengo el rol del usuario login o PUBLIC si es un usuario publico
    const userRol = this.authService.getUserRol();

    if (par_id_tipo_nl == null) {

      let filters = '';
      if (par_buscar != null) {
        filters = '&filters=' + par_buscar;
      }

      if (userRol === 'PUBLIC') {

        return this.http.get(this.configsService.getServerURL() + '/api/v1.0/spa/pub/tnl/?estado=H' + filters);
      } else {

        return this.http.get(this.configsService.getServerURL() + '/api/v1.0/spa/tnl/?estado=H' + filters);
      }
    } else {

      if (userRol === 'PUBLIC') {

        return this.http.get(this.configsService.getServerURL() + '/api/v1.0/spa/pub/tnl/?id=' + par_id_tipo_nl);
      } else {

        return this.http.get(this.configsService.getServerURL() + '/api/v1.0/spa/tnl/?id=' + par_id_tipo_nl);
      }
    }
  }

  getUO(par_id_uo?: string, par_buscar?: string) {

    if (par_id_uo == null) {

      let filters = '';
      if (par_buscar != null) {
        filters = '&filters=' + par_buscar;
      }
      return this.http.get(this.configsService.getServerURL() + '/api/v1.0/spa/uo/?estado=H' + filters);
    } else {
      return this.http.get(this.configsService.getServerURL() + '/api/v1.0/spa/uo/?id=' + par_id_uo);
    }
  }

  getVoces(par_id_voz_nl?: string, par_buscar?: string, par_tipo_caracter?: string) {

    // obtengo el rol del usuario login o PUBLIC si es un usuario publico
    const userRol = this.authService.getUserRol();

    if (par_id_voz_nl == null) {

      let parametros = '';

      if (par_buscar != null) {
        parametros += '&descripcion=' + par_buscar;
      }

      if (par_tipo_caracter != null) {
        parametros += '&tipo_caracter=' + par_tipo_caracter;
      }

      if (userRol === 'PUBLIC') {
        return this.http.get(this.configsService.getServerURL() + '/api/v1.0/spa/pub/vnl/?estado=H' + parametros);
      } else {
        return this.http.get(this.configsService.getServerURL() + '/api/v1.0/spa/vnl/?estado=H' + parametros);
      }
    } else {

      if (userRol === 'PUBLIC') {

        return this.http.get(this.configsService.getServerURL() + '/api/v1.0/spa/pub/vnl/?id=' + par_id_voz_nl);
      } else {

        return this.http.get(this.configsService.getServerURL() + '/api/v1.0/spa/vnl/?id=' + par_id_voz_nl);
      }
    }
  }

  getTiposNormasPorOficina(par_id_oficina?: string, par_id_tipo_nl?: string, par_buscar?: string) {

    if (par_id_oficina == null && par_id_tipo_nl == null) {

      let filters = '';
      if (par_buscar != null) {
        filters = '&filters=' + par_buscar;
      }
      return this.http.get(this.configsService.getServerURL() + '/api/v1.0/spa/oficina_tnl/?estado=H' + filters);
    } else if (par_id_oficina != null && par_id_tipo_nl == null) {
      return this.http.get(this.configsService.getServerURL() + '/api/v1.0/spa/oficina_tnl/?id_oficina=' + par_id_oficina + '&estado=H');
    } else if (par_id_oficina == null && par_id_tipo_nl != null) {
      return this.http.get(this.configsService.getServerURL() + '/api/v1.0/spa/oficina_tnl/?id_tipo_nl=' + par_id_tipo_nl + '&estado=H');
    } else if (par_id_oficina != null && par_id_tipo_nl != null) {
      return this.http.get(this.configsService.getServerURL() + '/api/v1.0/spa/oficina_tnl/?id_oficina=' + par_id_oficina + '&id_tipo_nl=' + par_id_tipo_nl + '&estado=H');
    }
  }

  getOficinas(par_id_oficina?: string, par_buscar?: string) {

    if (par_id_oficina === null) {

      let filters = '';

      if (par_buscar != null) {
        filters = '&filters=' + par_buscar;
      }
      return this.http.get(this.configsService.getServerURL() + '/api/v1.0/spa/oficina/?estado=H' + filters);
    } else {
      return this.http.get(this.configsService.getServerURL() + '/api/v1.0/spa/oficina/?id=' + par_id_oficina);
    }
  }

  getAfectaciones(par_id_afectacion_nl?: string, par_buscar?: string) {

    if (par_id_afectacion_nl == null) {

      let filters = '';
      if (par_buscar != null) {
        filters = '&filters=' + par_buscar;
      }
      return this.http.get(this.configsService.getServerURL() + '/api/v1.0/spa/anl/?estado=H' + filters);
    } else {
      return this.http.get(this.configsService.getServerURL() + '/api/v1.0/spa/anl/?id=' + par_id_afectacion_nl);
    }
  }

  getPDF(par_id_pdf) {
    
    // obtengo el rol del usuario login o PUBLIC si es un usuario publico
    const userRol = this.authService.getUserRol();

    if (userRol === 'PUBLIC') {

      return this.http.get(this.configsService.getServerURL() + '/api/v1.0/spa/pub/docs?id_pdf=' + par_id_pdf, {responseType: 'blob' as 'json'});
    } else {

      return this.http.get(this.configsService.getServerURL() + '/api/v1.0/spa/docs?id_pdf=' + par_id_pdf, {responseType: 'blob' as 'json'});
    }
  }

  getNormaLegal(id_tipo_nl: string, numero: string): Observable<any[]> {

    return this.http.get<any[]>(this.configsService.getServerURL() + '/api/v1.0/spa/nl/?id_tipo_nl=' + id_tipo_nl + '&numero=' + numero);
  }

  deleteNorma(obj) {
    // console.log('deleting norma');
    // console.log(obj + 'a');
    return this.http.put(this.configsService.getServerURL() + '/api/v1.0/spa/nl/',  obj );
  }

  saveNormaLegal(par_norma_legal): Observable<any[]> {

    const formData = new FormData();

    formData.append('id', par_norma_legal['id_norma_legal']);
    formData.append('id_tipo_nl', par_norma_legal['id_tipo_nl']);
    formData.append('numero', par_norma_legal['numero']);
    formData.append('fecha_firma', par_norma_legal['fecha_firma']);
    formData.append('caracter', par_norma_legal['caracter']);
    formData.append('id_voz_nl', par_norma_legal['id_voz_nl']);
    formData.append('resena', par_norma_legal['resena']);
    formData.append('nl_afectadas', JSON.stringify(par_norma_legal['nl_afectadas']));
    formData.append('oficinas_notif', JSON.stringify(par_norma_legal['oficinas_notif']));
    formData.append('cambio_pdf', par_norma_legal['cambio_pdf']);
    formData.append('file', par_norma_legal['file']);

    return this.http.post<any[]>(this.configsService.getServerURL() + '/api/v1.0/spa/nl/', formData);
  }

  triggerOCRProcessing(par_id_norma_legal: string) {
    
    console.log('sending id to process OCR. to back..');
    return this.http.get(this.configsService.getServerURL() + '/api/v1.0/spa/docs/ocr?id_norma_legal=' + par_id_norma_legal);
  }

  getOficinasNotif(par_id_oficina_notif?: string, par_buscar?: string) {

    if (par_id_oficina_notif == null) {

      let filters = '';
      if (par_buscar != null) {
        filters = '&filters=' + par_buscar;
      }
      return this.http.get(this.configsService.getServerURL() + '/api/v1.0/spa/oficina_notif/?estado=H' + filters);
    } else {
      return this.http.get(this.configsService.getServerURL() + '/api/v1.0/oficina_notif/vnl/?id=' + par_id_oficina_notif);
    }
  }

  sendEmail(par_obj_to_email: any[]) {

    return this.http.post(this.configsService.getServerURL() + '/api/v1.0/spa/send-mail/', par_obj_to_email);
  }
}
