import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ConfigsService } from '../../services/configs.service';

import {MenuItem} from 'primeng/api';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  isCollapsed = true;

  loggedUserName = '';
  userRol = '';
  userArea = '';
  userDataLogged = '';

  showConsultaNL = false;
  showNuevaNorma = false;
  showUnidadOrganizativa = false;
  showOficina = false;
  showTipoNL = false;
  showTipoNLxOficina = false;
  showAfectacionNL = false;
  showVozNL = false;
  showOficinaNotif = false;
  uri_ = '';
  showLogin = true;

  items: MenuItem[];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private configService: ConfigsService) { }

  ngOnInit() {

    this.showLogin = false;
    this.authService.setFirstLog(true);

    // window.location.href returns the href (URL) of the current page
    this.uri_ = window.location.href;

    // Note: Below 'queryParams' can be replaced with 'params' depending on your requirements
    this.activatedRoute.queryParams.subscribe(params => {

      if (params.nl) {

        if (this.authService.getAccessJWT() === 'no_token') {
          // si no hay token

          this.showLogin = true;
          this.userRol = 'PUBLIC';
        } else {
          // si hay un token

          this.showLogin = false;
          this.userArea = this.authService.getUserDataArea()[0];
          this.loggedUserName = this.authService.getUserData()['given_name'] + ' ' + this.authService.getUserData()['family_name'];
          this.userRol = this.authService.getUserRol();
          this.userDataLogged = this.userArea + ' | ' + this.loggedUserName + ' | ' + this.userRol.toLowerCase();
        }

        this.showHideMenuOptions();
        this.router.navigate(['/normas'], { queryParams: { nl: params.nl } });
      } else {

        const the_code = this.uri_.split('code=');
        const my_obj = {};
        my_obj['code'] = the_code[1];

        if (my_obj['code'] !== undefined) {

          window.history.replaceState({}, document.title, '/' + './');     

          this.authService.getTokenWithTempCode(my_obj['code']).subscribe(rta => {
            
            if (rta === true) {

              this.authService.setFirstLog(false);
              // everythin well ok with getting and saving jwt

              this.userArea = this.authService.getUserDataArea()[0];
              this.loggedUserName = this.authService.getUserData()['given_name'] + ' ' + this.authService.getUserData()['family_name'];
              this.userRol = this.authService.getUserRol();

              if (this.userRol === 'PUBLIC') {
                // Si el rol es PUBLIC  

                this.userDataLogged = this.loggedUserName;
              } else {
                // Si el rol no es PUBLIC  

                if (this.userRol) {
                  // si el usuario tiene un rol definido

                  this.userDataLogged = this.userArea + ' | ' + this.loggedUserName + ' | ' + this.userRol.toLowerCase();
                } else {


                }
              }
              
              this.showHideMenuOptions();
              this.router.navigate(['/normas']);
            } else { 
              // not forwarded from keycloak in keycloak OR some other error happened

              localStorage.removeItem('access_token');
              this.router.navigate(['/logout']);
            }
          });
        } else {
          // no hay un usuario login
          // it means that user could have refreshed the url ... will load user in topbar, if localstorage data is present.

          if (this.authService.getAccessJWT() === 'no_token') {
            // si no hay token

            this.showLogin = true;
            this.userRol = 'PUBLIC';
          } else {
            // si hay un token

            this.showLogin = false;
            this.userArea = this.authService.getUserDataArea()[0];
            this.loggedUserName = this.authService.getUserData()['given_name'] + ' ' + this.authService.getUserData()['family_name'];
            this.userRol = this.authService.getUserRol();
            this.userDataLogged = this.userArea + ' | ' + this.loggedUserName + ' | ' + this.userRol.toLowerCase();
          }

          this.showHideMenuOptions();
          this.router.navigate(['/normas']);
        }
      }
    });
  }
  
  use_jwt(resp) {

    if (resp) {
      // console.log('DECODED TOKEN SAVED IS=========D');
      // console.log(this.authService.getUserData());
      // this.loggedUserName=this.authService.getUserData().name
    }
  }

  showHideMenuOptions() {

    this.showConsultaNL = false;
    this.showNuevaNorma = false;
    this.showUnidadOrganizativa = false;
    this.showOficina = false;
    this.showTipoNL = false;
    this.showTipoNLxOficina = false;
    this.showAfectacionNL = false;
    this.showVozNL = false;
    this.showOficinaNotif = false;

    if (this.userRol === 'PUBLIC') {

      this.showConsultaNL = true;

    } else if (this.userRol === 'ASISTENTE') {

      this.showConsultaNL = true;
      this.showUnidadOrganizativa = true;
      this.showOficina = true;
      this.showTipoNL = true;
      this.showTipoNLxOficina = true;
      this.showAfectacionNL = true;
      this.showVozNL = true;
      this.showOficinaNotif = true;
    } else if (this.userRol === 'ADMIN') {

      this.showConsultaNL = true;
      this.showNuevaNorma = true;
    } else if (this.userRol === 'OPERADOR') {

      this.showConsultaNL = true;
      this.showNuevaNorma = true;
    }

    this.items = [
      {
        label: 'Consultar', 
        icon: 'pi pi-fw pi-search',
        routerLink: ['/normas'],
        visible: this.showConsultaNL
      },
      {
        label: 'Nueva Norma Legal', 
        routerLink: ['/nueva'],
        icon: 'pi pi-fw pi-file',
        visible: this.showNuevaNorma
      },
      {
        label: 'Administración de datos',
        icon: 'pi pi-fw pi-cog',
        visible: this.showUnidadOrganizativa && this.showOficina && this.showTipoNL && this.showTipoNLxOficina && this.showAfectacionNL && this.showVozNL && this.showOficinaNotif,
        items: [
          {
            label: 'Unidad Organizativa', 
            routerLink: ['/uo'],
            visible: this.showUnidadOrganizativa
          },
          {
            label: 'Oficina', 
            routerLink: ['/oficina'],
            visible: this.showOficina
          },
          {
            label: 'Tipo de Norma Legal', 
            routerLink: ['/tipo-norma-legal'],
            visible: this.showTipoNL
          },
          {
            label: 'Tipo de Norma Legal por Oficina', 
            routerLink: ['/oficina-tipo-norma-legal'],
            visible: this.showTipoNLxOficina
          },
          {
            label: 'Afectación de Norma Legal', 
            routerLink: ['/afectacion-norma-legal'],
            visible: this.showAfectacionNL
          },
          {
            label: 'Voz de Norma Legal', 
            routerLink: ['/voz-norma-legal'],
            visible: this.showVozNL
          },
          {
            label: 'Oficinas a Notificar', 
            routerLink: ['/oficina-notif'],
            visible: this.showOficinaNotif
          }
        ]
      }
    ];    
  }

  login() {
    // The window.location object can be used to get the current page address (URL) and to redirect the browser to a new page
    window.location.href = this.configService.getKeyCloakAddress() + '/auth/realms/' + this.configService.getKeyCloakRealm() + '/protocol/openid-connect/auth?client_id=' + this.configService.getKeyCloakClientID() + '&nonce=te7s5t9t&response_mode=fragment&response_type=code&login=true&redirect_uri=' + this.configService.getServerFrontURL();
    this.showLogin = false; 
  }

  logout() {
    this.showLogin = true;
    this.authService.logout();
  }

  // closeSideBar() {

  //   this.showSideBar = false;
  // }
}
