import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocViewerComponent } from '../doc-viewer/doc-viewer.component';
import { AuthService } from '../../services/auth.service';
import { NormaService } from '../../services/norma.service';
import { ConfigsService } from '../../services/configs.service';
import { MessageService } from 'primeng/api';
// import * as jsPDF from 'jspdf';
import { Subscription } from 'rxjs';
import { CommunicationService } from '../../services/communication.service';
// import { ConsoleReporter } from 'jasmine';

@Component({
  selector: 'app-norma-detail',
  templateUrl: './norma-detail.component.html',
  styleUrls: ['./norma-detail.component.scss']
})
export class NormaDetailComponent implements OnInit {

  userDataArea = '';
  userRol = '';

  norma: any = {};

  afecta = [];
  afectadas = [];
  oficinas_notif = [];

  all = {};
  data: any = {};
  userIsAdmin = false;
  dialogRef: any;
  f_display_pdf_erase_confirmation = false;
  switchToEraseConfirmed = false;

  confirmed_del_value = '';
  btnDeleteWarning = true;
  delete_motive = '';
  eraseFailed = false;
  showMotivoEmptyErr = '';
  reportRows = 0;
  subscription: Subscription;

  show_afectada_por = false;
  show_afecta_a = false;
  show_oficinas_notif = true;
  show_detail_warning = false;
  show_ocr = false;
  show_link_copied = false;


  canDelete = false;

  constructor(@Inject(MAT_DIALOG_DATA) data,
    public dialog: MatDialog,
    public dialogDetail: MatDialog,
    private authService: AuthService,
    private normaService: NormaService,
    private messageService: MessageService,
    private communicationService: CommunicationService,
    private configService: ConfigsService ) {
    this.data = data;
  }

  ngOnInit() {

    this.canDelete = false;
    if (this.data.userRol === 'ADMIN' && this.data.editable) {
      this.canDelete = true;
    } 

    this.data = this.data.data;

    let f = new Date(this.data.norma_legal['fecha_firma']);
    this.data.norma_legal['fecha_firma'] = f.getDate() + '/' + (f.getMonth() + 1) + '/' + f.getFullYear();

    f = new Date(this.data.norma_legal['fecha_publicacion']);
    this.data.norma_legal['fecha_publicacion'] = f.getDate() + '/' + (f.getMonth() + 1) + '/' + f.getFullYear();

    this.norma = this.data.norma_legal;

    // show and hide table in detail if there are no records
    this.show_afecta_a = this.data.normas_legales_afectadas.length > 0 ? true : false;
    this.show_afectada_por = this.data.normas_legales_que_la_afectaron.length > 0 ? true : false;

    if (this.userRol === 'PUBLIC') {
      this.show_oficinas_notif = false;
    } else {
      this.show_oficinas_notif = this.data.normas_legales_oficinas_notif.length > 0 ? true : false;
    }

    for (let a = 0; a < this.data.normas_legales_afectadas.length; a++) {

      let f = new Date(this.data.normas_legales_afectadas[a]['fecha_firma']);
      this.data.normas_legales_afectadas[a]['fecha_firma'] = f.getDate() + '/' + (f.getMonth() + 1) + '/' + f.getFullYear();
      f = new Date(this.data.normas_legales_afectadas[a]['fecha_publicacion']);
      this.data.normas_legales_afectadas[a]['fecha_publicacion'] = f.getDate() + '/' + (f.getMonth() + 1) + '/' + f.getFullYear();
      f = new Date(this.data.normas_legales_afectadas[a]['fecha_publicacion_afectacion']);
      this.data.normas_legales_afectadas[a]['fecha_publicacion_afectacion'] = f.getDate() + '/' + (f.getMonth() + 1) + '/' + f.getFullYear();
    }

    this.show_detail_warning = false;

    for (let a = 0; a < this.data.normas_legales_que_la_afectaron.length; a++) {
      if (this.data.normas_legales_que_la_afectaron[a]['destacado_afectacion'] == true) {
        this.show_detail_warning = true;
      }
      let f = new Date(this.data.normas_legales_que_la_afectaron[a]['fecha_firma']);
      this.data.normas_legales_que_la_afectaron[a]['fecha_firma'] = f.getDate() + '/' + (f.getMonth() + 1) + '/' + f.getFullYear();

      f = new Date(this.data.normas_legales_que_la_afectaron[a]['fecha_publicacion']);
      this.data.normas_legales_que_la_afectaron[a]['fecha_publicacion'] = f.getDate() + '/' + (f.getMonth() + 1) + '/' + f.getFullYear();

      f = new Date(this.data.normas_legales_que_la_afectaron[a]['fecha_publicacion_afectacion']);
      this.data.normas_legales_que_la_afectaron[a]['fecha_publicacion_afectacion'] = f.getDate() + '/' + (f.getMonth() + 1) + '/' + f.getFullYear();
    }

    this.afecta = this.data.normas_legales_que_la_afectaron;
    this.afectadas = this.data.normas_legales_afectadas;
    this.oficinas_notif = this.data.normas_legales_oficinas_notif;
    this.all = this.data;
  }

  showDoc(id) {
    this.openDialog(id);
  }

  openDialog(par_id_pdf): void {

    this.dialogRef = this.dialog.open(DocViewerComponent, {
      width: '90%',
      height: '70%',
      data: {id_pdf: par_id_pdf}
    });

    this.dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }

  increasePageRow(n) {
    this.reportRows += n;
  }


  // generate_report() {
  //   let data_ = this.all
  //   console.log("data in report is")
  //   console.log(data_)
  //   let fila: number
  //
  //   let doc = new jsPDF()
  //   fila = 20
  //   doc.setFontStyle('bold')
  //   doc.setFontSize(14)
  //   doc.text('PODER JUDICIAL DE MENDOZA', 60, fila)
  //   fila += 8
  //   doc.text('Gestión de Normas Legales', 64, fila)
  //   fila += 8
  //   doc.setFontStyle('normal')
  //   doc.line(10, fila, 200, fila)
  //   fila += 8
  //   doc.text('== Detalle de Norma Legal ==', 68, fila)
  //   fila += 5
  //   doc.setFontSize(11)
  //   doc.setTextColor(270, 0, 0);
  //   doc.text(150, 80, this.show_detail_warning ? "Norma sin Vigencia" : "");
  //   doc.setTextColor(0, 0, 0);
  //   doc.line(10, fila, 200, fila)
  //   fila += 15
  //
  //   doc.text(data_['norma_legal']['descripcion_tipo_nl'], 15, fila)
  //   if (fila > 270) {
  //     doc.addPage();
  //     fila = 20
  //   } else {
  //     fila += 10
  //   }
  //   doc.text(data_['norma_legal']['descripcion_uo'], 15, fila)
  //   if (fila > 270) {
  //     doc.addPage();
  //     fila = 20
  //   } else {
  //     fila += 10
  //   }
  //   doc.text('Número de Norma Legal: ' + data_['norma_legal']['numero'], 15, fila)
  //   if (fila > 270) {
  //     doc.addPage();
  //     fila = 20
  //   } else {
  //     fila += 10
  //   }
  //   doc.text('Fecha de Publicación: ' + data_['norma_legal']['fecha_publicacion'], 15, fila)
  //   if (fila > 270) {
  //     doc.addPage();
  //     fila = 20
  //   } else {
  //     fila += 10
  //   }
  //   doc.text("Fecha de Firma: " + data_['norma_legal']['fecha_firma'], 15, fila)
  //   if (fila > 270) {
  //     doc.addPage();
  //     fila = 20
  //   } else {
  //     fila += 10
  //   }
  //   doc.text("Voz: " + data_['norma_legal']['descripcion_voz_nl'], 15, fila)
  //   if (fila > 270) {
  //     doc.addPage();
  //     fila = 20
  //   } else {
  //     fila += 10
  //   }
  //   doc.text("Carácter: " + data_['norma_legal']['caracter'], 15, fila)
  //   if (fila > 270) {
  //     doc.addPage();
  //     fila = 20
  //   } else {
  //     fila += 10
  //   }
  //   doc.text("Usuario: " + data_['norma_legal']['username'], 15, fila)
  //   if (fila > 270) {
  //     doc.addPage();
  //     fila = 20
  //   } else {
  //     fila += 10
  //   }
  //
  //   let textLines = data_['norma_legal']['resena'].match(/(.|[\r\n]){1,65}/g);
  //   console.log("texxtLines", textLines)
  //   doc.text("Tema: ", 15, fila)
  //   if (fila > 270) {
  //     doc.addPage();
  //     fila = 20
  //   } else {
  //     fila += 10
  //   }
  //   for (let co = 0; co < textLines.length; co++) {
  //     doc.text(textLines[co], 15, fila)
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //   }
  //
  //
  //   if (fila > 270) {
  //     doc.addPage();
  //     fila = 0
  //   } else {
  //     fila += 10
  //   }
  //   doc.line(10, fila, 200, fila)
  //   if (fila > 270) {
  //     doc.addPage();
  //     fila = 0
  //   } else {
  //     fila += 10
  //   }
  //
  //
  //   //adding title only if there are some afectadas in the array
  //   if (this.afectadas.length > 0) {
  //     doc.setFontStyle('bold')
  //     doc.text("Normas afectadas: ", 15, fila)
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //   }
  //   for (let a = 0; a < this.afectadas.length; a++) {
  //     doc.line(10, fila, 200, fila)
  //     doc.setFontStyle('normal')
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //     doc.text("Origen: " + this.afectadas[a]['descripcion_uo'], 15, fila)
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //     doc.text("Oficina: " + this.afectadas[a]['descripcion_oficina'], 15, fila)
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //     doc.text("Tipo de Norma Legal: " + this.afectadas[a]['descripcion_tipo_nl'], 15, fila)
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //     doc.text("Número: " + this.afectadas[a]['numero'], 15, fila)
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //     doc.text("Tipo de Afectación: " + this.afectadas[a]['descripcion_afectacion'], 15, fila)
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //     doc.text("Caracter: " + this.afectadas[a]['caracter'], 15, fila)
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //
  //     doc.text("Voz: " + this.afectadas[a]['descripcion_voz_nl'], 15, fila)
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //     doc.text("Fecha de Firma: " + this.afectadas[a]['fecha_firma'], 15, fila)
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //     doc.text("Fecha de Publicación: " + this.afectadas[a]['fecha_publicacion'], 15, fila) //new page
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //     doc.text("Fecha de Publicación de la Afectación: " + this.afectadas[a]['fecha_publicacion_afectacion'], 15, fila)
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //     doc.text("Usuario: " + this.afectadas[a]['username'], 15, fila)
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //
  //     doc.text("Descripcion: " + this.afectadas[a]['descripcion_afectacion'], 15, fila)
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //     //iterate through possible long paragraph and cut it in lines.
  //     let textLines = this.afectadas[a]['detalle_afectacion'].match(/(.|[\r\n]){1,65}/g);
  //     console.log("texxtLines", textLines)
  //     doc.text("Detalle: ", 15, fila)
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //     for (let co = 0; co < textLines.length; co++) {
  //       doc.text(textLines[co], 15, fila)
  //       if (fila > 270) {
  //         doc.addPage();
  //         fila = 20
  //       } else {
  //         fila += 10
  //       }
  //     }
  //
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //
  //     // doc.text("Fecha Publicación: " + this.afectadas[a]['fecha_publicacion'], 15, fila)
  //     //   if (fila > 270){doc.addPage();fila=20}else{fila+=10}
  //   }
  //
  //   if (this.afecta.length > 0) {
  //     doc.line(10, fila, 200, fila)
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //     doc.setFontStyle('bold')
  //     doc.text("Normas que afectan la presente: ", 15, fila)
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //   }
  //   for (let a = 0; a < this.afecta.length; a++) {
  //
  //     doc.line(10, fila, 200, fila)
  //
  //
  //     //doc.line(10, fila, 200, fila)
  //
  //     doc.setFontStyle('normal')
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //     doc.text("Origen: " + this.afecta[a]['descripcion_uo'], 15, fila)
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //     doc.text("Oficina: " + this.afecta[a]['descripcion_oficina'], 15, fila)
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //
  //     doc.text("Tipo de Norma Legal: " + this.afecta[a]['descripcion_tipo_nl'], 15, fila)
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //     doc.text("Número: " + this.afecta[a]['numero'], 15, fila)
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //     doc.text("Tipo de Afectación: " + this.afecta[a]['descripcion_afectacion'], 15, fila)
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //     doc.text("Caracter: " + this.afecta[a]['caracter'], 15, fila)
  //
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //     doc.text("Voz: " + this.afecta[a]['descripcion_voz_nl'], 15, fila)
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //     doc.text("Fecha de Firma: " + this.afecta[a]['fecha_firma'], 15, fila)
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //     doc.text("Fecha de Publicación: " + this.afecta[a]['fecha_publicacion'], 15, fila) //new page
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //     doc.text("Fecha de Publicación de la Afectación: " + this.afecta[a]['fecha_publicacion_afectacion'], 15, fila)
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //     doc.text("Usuario: " + this.afecta[a]['username'], 15, fila)
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //     doc.text("Descripcion: " + this.afecta[a]['descripcion_afectacion'], 15, fila)
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //     //iterate through possible long paragraph and cut it in lines.
  //     let textLines = this.afecta[a]['detalle_afectacion'].match(/(.|[\r\n]){1,65}/g);
  //     console.log("texxtLines", textLines)
  //     doc.text("Detalle: ", 15, fila)
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //     for (let co = 0; co < textLines.length; co++) {
  //       doc.text(textLines[co], 15, fila)
  //       if (fila > 270) {
  //         doc.addPage();
  //         fila = 20
  //       } else {
  //         fila += 10
  //       }
  //     }
  //
  //     if (fila > 270) {
  //       doc.addPage();
  //       fila = 20
  //     } else {
  //       fila += 10
  //     }
  //   }
  //
  //
  //   doc.line(10, fila, 200, fila)
  //   if (fila > 270) {
  //     doc.addPage();
  //     fila = 20
  //   } else {
  //     fila += 10
  //   }
  //   doc.setFontStyle('italic')
  //   doc.setFontSize(9);
  //   var options = {
  //     weekday: 'long',
  //     year: 'numeric',
  //     month: 'long',
  //     day: 'numeric'
  //   };
  //   let fe_ = new Date()
  //   //console.log(event.toLocaleDateString('es-419', options));
  //   doc.text('** Reporte Generado el ' + fe_.toLocaleDateString('es-419', options), 15, fila)
  //   if (fila > 270) {
  //     doc.addPage();
  //     fila = 20
  //   } else {
  //     fila += 10
  //   }
  //   doc.line(10, fila, 200, fila)
  //   doc.save(new Date().toLocaleDateString().toString() + "_" + data_['norma_legal']['numero'] + ".pdf")
  // }

  delete() {

    this.btnDeleteWarning = true;
    this.confirmed_del_value = '';
    this.f_display_pdf_erase_confirmation = true;
  }

  checkButton(event) {

    const voz_nl = event.target['value'];
    this.btnDeleteWarning = true;

    if (this.norma['descripcion_voz_nl'] === voz_nl) {
      this.btnDeleteWarning = false;
    }
  }

  checkMotiveDelete() {

    if (this.delete_motive.trim() !== '') {
      this.showMotivoEmptyErr = '';
    } else {
      this.showMotivoEmptyErr = 'Por favor ingrese el motivo de eliminación';
    }
  }

  delete_confirmed() {
    
    this.showMotivoEmptyErr = '';

    if (this.delete_motive.trim() === '') {
      // Si el usuario no ingresó el motivo de la eliminación

      this.showMotivoEmptyErr = 'Por favor ingrese el motivo de eliminación';
    } else {
      // Si el usuario sí ingresó el motivo de la eliminación
      
      const obj = {};
      obj['id'] = this.norma['id'];
      obj['motivo'] = this.delete_motive;
      obj['username'] = this.authService.getUserData().preferred_username;
      obj['rol_auth'] = this.authService.getUserDataArea()[0];
      obj['tipo_operacion'] = 'disabled'; // due to requirements, there will only be one workplace per userRol

      this.normaService.deleteNorma(obj).subscribe(data => {
        
        if (data['code'] == '103') {

          // console.log('CODE 103 RECEIVED');
          // console.log('switchToEraseConfirmed3');
          // console.log(this.switchToEraseConfirmed);
          this.eraseFailed = false;
          this.switchToEraseConfirmed = true;

          this.f_display_pdf_erase_confirmation = false;

          // console.log('switchToEraseConfirmed4');
          // console.log(this.switchToEraseConfirmed);
          this.delay(2500).then(any => {

            this.dialog.closeAll();

            this.communicationService.sendMessage({
              'status': 'success'
            });

          });
        } else {
          this.switchToEraseConfirmed = false;
          this.eraseFailed = true;
          this.f_display_pdf_erase_confirmation = false;
          this.delay(2500).then(any => {
            this.f_display_pdf_erase_confirmation = false;
            this.dialog.closeAll();
          });
        }
      });
    }
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms)).then(() => console.log('fired'));
  }

  toastAddSingle(msg, warn) {
    this.messageService.add({
      severity: 'warning',
      summary: 'Service Message',
      detail: 'damn test'
    });
    if (warn) {
      this.messageService.add({
        severity: 'warning',
        summary: 'Service Message',
        detail: msg
      });
    }
    console.log('got in show toast' + warn);
    this.messageService.add({
      severity: 'success',
      summary: 'Service Message',
      detail: msg
    });
  }

  toastClear() {
    this.messageService.clear();
  }

  show_ocr_text() {
    // removing starting /n from document ...until something else is reached.
    let stay_here_please = true;
    while (stay_here_please) {

      if (/\s/.test(this.norma['ocr_text'].substring(0, 2))) {
        console.log('found starting whitespace... removing.');
        this.norma['ocr_text'] = this.norma['ocr_text'].substring(2, this.norma['ocr_text'].length);
    } else {
      stay_here_please = false;
    }


  }
  this.show_ocr = true;
  }

  linkToClipboard() {

    const textarea = document.createElement('textarea');
    textarea.style.position = 'fixed';
    textarea.style.top = '0';
    textarea.style.left = '0';

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textarea.style.width = '1px';
    textarea.style.height = '1px';

    // We don't need padding, reducing the size if it does flash render.
    textarea.style.padding = '0';

    // Clean up any borders.
    textarea.style.border = 'none';
    textarea.style.outline = 'none';
    textarea.style.boxShadow = 'none';

    // Avoid flash of white box if rendered for any reason.
    textarea.style.background = 'transparent';
    document.querySelector('body').appendChild(textarea);

    textarea.value = this.configService.getServerFrontURL() + '?nl=' + this.data.norma_legal.id_pdf;

    textarea.select();

    document.execCommand('copy');

    this.show_link_copied = true;
  }

  sendMail(par_item: any) {

    const obj_to_email = {};
    obj_to_email['id_norma_legal'] = par_item['id_norma_legal'];
    obj_to_email['id_oficina_notif'] = par_item['id_oficina_notif'];

    this.normaService.sendEmail([obj_to_email]).subscribe(data => {

      if (data['code'] !== undefined) {
        console.log('se produjo un error al enviar el mail a as oficinas de destino');
        this.messageService.add({severity: 'warn', summary: '', detail: 'Se produjo un error al enviar el email'});
      }
    });
  }
}
