import { Component, OnInit } from '@angular/core';
import { Oficina } from '../../models/oficina';
import { TipoNormaLegal } from '../../models/tipo-norma-legal';
import { MessageService } from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import { NormaService } from '../../services/norma.service';
import { CrudsService } from '../../services/cruds.service';

@Component({
  selector: 'app-oficina-tipo-norma-legal',
  templateUrl: './oficina-tipo-norma-legal.component.html',
  styleUrls: ['./oficina-tipo-norma-legal.component.scss']
})
export class OficinaTipoNormaLegalComponent implements OnInit {

  dpdn_oficinas: Oficina[] = [];
  dpdn_selected_oficina: Oficina = undefined;

  uo_descripcion = '';

  tbl_columnas = [];
  tbl_tipo_nl: TipoNormaLegal[] = [];
  tbl_tipo_nl_selected: TipoNormaLegal[] = [];
  tbl_paginatorPosition = 'bottom';
  tbl_loading = false;
  tbl_show = false;

  constructor(
    private normaService: NormaService,
    private messageService: MessageService,
    private crudsService: CrudsService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit() {

    this.tbl_columnas = [
      { field: 'descripcion', header: 'Tipo de Norma Legal' }
    ];

    this.tbl_loading = true;

    this.clear_components();
    this.load_data();
  }

  get_oficina_tipo_nl() {

    this.uo_descripcion = this.dpdn_selected_oficina.descripcion_uo;
    this.tbl_tipo_nl_selected = [];
    this.tbl_show = false;

    this.normaService.getTiposNormasPorOficina(this.dpdn_selected_oficina.id).subscribe(data => {

      const obj = data['data'];

      const array_select = [];
      let object = {};
      for (let i = 0; i < obj.length; i++) {
        for (let j = 0; j < this.tbl_tipo_nl.length; j++ ) {
          if (obj[i].id_tipo_nl == this.tbl_tipo_nl[j].id) {
            object = this.tbl_tipo_nl[j];
            array_select.push(object);
          }
        }
      }
      this.tbl_tipo_nl_selected = [...array_select];
      this.tbl_show = true;
    });
  }

  cancelar() {
    this.clear_components();
  }

  aceptar() {

    let datos_ok = true;

    if (this.dpdn_selected_oficina == undefined) {
      // si el usuario no selecciono

      this.messageService.add({severity: 'warn', summary: '', detail: 'Indique la Oficina'});
      datos_ok = false;
    }

    if (datos_ok) {
      // Si datos ok

      const object: any = {};
      let caso_uso_mensaje: string;

      this.guardar();
    }
  }

  guardar() {

    this.crudsService.oficinaTipoNormaLegal(this.dpdn_selected_oficina, this.tbl_tipo_nl_selected).subscribe(data => {

      if (data['code'] != undefined) {
        // Si se guardo ok

        this.messageService.add({severity: 'success', summary: '', detail: 'Tipos de Norma Legales guardadas correctamente para la Oficina'});
        this.clear_components();
      } else {
        // Si no se guardo ok

        if (data['error_code'] != undefined) {
          this.messageService.add({severity: 'warn', summary: '', detail: data['error_code'] + ' - ' + data['error_description'] + ' - ' + data['message_code'] + ' - ' + data['message_description']});
        }
      }
    });
  }

  load_data(par_buscar?: string) {

    this.normaService.getOficinas(null, null).subscribe(data => {
      const obj = data['data'];
      this.dpdn_oficinas = [...obj];
    });

    this.normaService.getTiposNormas(null, par_buscar).subscribe(data => {
      const list = data['data'];
      this.tbl_tipo_nl = list;
      this.tbl_loading = false;
    });
  }

  clear_components() {

    this.dpdn_selected_oficina = undefined;
    this.uo_descripcion = '';
    this.tbl_tipo_nl_selected = [];
    this.tbl_show = false;
  }

  estilo_columnas(par_tipo: string, par_columna: string) {
    let estilo = '';

    if (par_columna == 'descripcion') {
      estilo = par_tipo == 'head' ? 'descripcion_head_style' : 'descripcion_style';
    }
    return estilo;
  }
}
